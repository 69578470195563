import queryString from 'query-string';
import dayjs from 'dayjs';
import { BoatPackage } from 'swagger/models';

export const apiHourNameMap = {
  2: 'two_hour_price_cents',
  3: 'three_hour_price_cents',
  4: 'half_day_cents',
  6: 'six_hour_price_cents',
  8: 'all_day_cents',
};

export const apiHourToTemporaryMap = {
  two_hour_price_cents: 'boat_two_hour_price_cents',
  three_hour_price_cents: 'boat_three_hour_price_cents',
  half_day_cents: 'boat_half_day_price_cents',
  six_hour_price_cents: 'boat_six_hour_price_cents',
  all_day_cents: 'boat_all_day_price_cents',
};

export const apiHourNameMapReverse = {
  two_hour_price_cents: 2,
  three_hour_price_cents: 3,
  half_day_cents: 4,
  six_hour_price_cents: 6,
  all_day_cents: 8,
};

export const durationToPackagePrice = {
  two_hours: 'two_hour_price_cents',
  three_hours: 'three_hour_price_cents',
  half_day: 'half_day_cents',
  six_hours: 'six_hour_price_cents',
  all_day: 'all_day_cents',
};

export const apiParamsHourNameMapReverse = {
  two_hours: 2,
  three_hours: 3,
  half_day: 4,
  six_hours: 6,
  full_day: 8,
  all_day: 8,
  multi_day: -1,
};

const apiCaptainHourNameMap = {
  2: 'two_hours_captain_fee',
  3: 'three_hours_captain_fee',
  4: 'half_day_captain_fee',
  6: 'six_hours_captain_fee',
  8: 'full_day_captain_fee',
};

export const apiParamsHourNameMap = {
  2: 'two_hours',
  3: 'three_hours',
  4: 'half_day',
  6: 'six_hours',
  8: 'full_day',
  [-1]: 'multi_day',
};

const getPriceOfDuration = (pack: any, duration: number, isBareboat: boolean) => {
  const captainPrice =
    pack[apiHourNameMap[duration]] === null
      ? 0
      : pack[apiHourNameMap[duration]] + pack[apiCaptainHourNameMap[duration]];

  return isBareboat ? pack[apiHourNameMap[duration]] : captainPrice;
};

export const findCheapestPackage = (boat: any) => {
  const pricing = boat.packages.reduce((prev, currentPackage) => {
    const isBareboat = currentPackage.package_type === 'bareboat';

    const newPackage = {
      ...prev[currentPackage.package_type],
      2: getPriceOfDuration(currentPackage, 2, isBareboat),
      3: getPriceOfDuration(currentPackage, 3, isBareboat),
      4: getPriceOfDuration(currentPackage, 4, isBareboat),
      6: getPriceOfDuration(currentPackage, 6, isBareboat),
      8: getPriceOfDuration(currentPackage, 8, isBareboat),
    };
    return {
      ...prev,
      [currentPackage.package_type]: new Map(
        Object.entries(newPackage)
          .sort(([, v1]: any, [, v2]: any) => v1 - v2)
          .filter(([, v1]) => !!v1)
      ),
    };
  }, {});

  const [cheapestCaptainPackage] = pricing.captained?.keys() || [];
  const [cheapestBareboatPackage] = pricing.bareboat?.keys() || [];

  if (!cheapestBareboatPackage) {
    return {
      package_type: 'captained',
      duration: cheapestCaptainPackage,
      price: pricing.bareboat?.get(cheapestCaptainPackage),
    };
  }

  if (!cheapestCaptainPackage) {
    return {
      package_type: 'bareboat',
      duration: cheapestBareboatPackage,
      price: pricing.bareboat?.get(cheapestBareboatPackage),
    };
  }

  return pricing.bareboat?.get(cheapestBareboatPackage) < pricing.captained?.get(cheapestCaptainPackage)
    ? {
        package_type: 'bareboat',
        duration: cheapestBareboatPackage,
        price: pricing.bareboat?.get(cheapestBareboatPackage),
      }
    : {
        package_type: 'captained',
        duration: cheapestCaptainPackage,
        price: pricing.captained?.get(cheapestCaptainPackage),
      };
};

export type GetCharterTypeUrlType = {
  passengers: number;
  boatId: string;
  tz: string;
  bookingId?: string;
  isBookingMultiDay: boolean;
  selectedPackage: Pick<BoatPackage, 'id' | 'package_type' | 'charter_agreement_type'>;
  tripStart: string;
  tripTime: string;
  duration: string;
  selectedCaptain?: {
    id: string;
    boatOwnerId: string;
  };
};

export const getCharterTypeUrl = ({
  passengers,
  boatId,
  isBookingMultiDay,
  selectedPackage,
  tripStart,
  tripTime,
  duration,
  bookingId,
  tz,
  selectedCaptain,
}: GetCharterTypeUrlType) => {
  const ownerIsCaptain = selectedCaptain?.id && selectedCaptain?.id === selectedCaptain?.boatOwnerId;
  const baseUrl =
    ownerIsCaptain || selectedPackage.charter_agreement_type === 'time_charter'
      ? '/legal/time_charter_party_agreement'
      : '/legal/bareboat_charter_party_agreement';
  const query = queryString.stringify(
    {
      'booking[boat_public_id]': boatId,
      'booking[package_public_id]': selectedPackage?.id,
      'booking[tz]': tz,
      'booking[package_type]': selectedPackage?.package_type,
      'booking[trip_start]': dayjs(tripStart)?.format('MM/DD/YYYY'),
      'booking[is_multi_day]': isBookingMultiDay,
      'booking[duration]': duration?.replace('_cents', ''),
      'booking[trip_time]': tripTime,
      'booking[passengers]': passengers,
    },
    {
      encode: false,
    }
  );

  if (bookingId) return `${baseUrl}?booking_id=${bookingId}`;

  return `${baseUrl}?${query}`;
};

export const createDurationOption = (hours: number, apiName: string) => ({
  value: hours,
  label: `${hours} hours`,
  name: apiName,
  available: true,
});

export const createMultiDayDurationOption = (tripFinish, tripStart) => {
  const dayCount = dayjs(tripFinish).diff(tripStart, 'day') + 1;
  return {
    value: -1,
    available: true,
    name: 'multi_day',
    label: `${dayCount} day${dayCount > 1 ? 's' : ''}`,
  };
};

export const priceAndPackageType = (packages: BoatPackage[], queryParams: { package_id: string; duration: string }) => {
  const selectedPackage = packages.find((p) => p.id === queryParams.package_id);
  const selectedPriceFromDuration = durationToPackagePrice[queryParams.duration];
  const price = selectedPackage[selectedPriceFromDuration];
  const captain = selectedPackage.package_type === 'captained' ? 'yes' : 'no';

  return { price, captain };
};
