export const sortObjectByObject = (objectToBeSorted, sortedObject) => {
  const flatOrder = Object.entries(sortedObject);
  const flatNeed = Object.entries(objectToBeSorted).sort(([key], [keyNext]) => {
    const indexKey = flatOrder.findIndex(([i]) => i === key);
    const indexKeyNext = flatOrder.findIndex(([i]) => i === keyNext);

    // 9999 because we want to put unsorted at the end
    return (indexKey === -1 ? 9999 : indexKey) - (indexKeyNext === -1 ? 9999 : indexKeyNext);
  });

  return flatNeed.reduce(
    (prev, [keyCur, valueCur]) => ({
      ...prev,
      [keyCur]: valueCur,
    }),
    {}
  );
};
