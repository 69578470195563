export const SORT_OPTIONS = {
  recommended: {
    value: 'recommended',
    label: 'Recommended',
    dropDown: 'Recommended',
  },
  newest: {
    value: 'newest',
    label: 'Most Recent',
    dropDown: 'Most Recent',
  },
  price_low_to_high: {
    value: 'price_low_to_high',
    label: 'Price low to high',
    dropDown: 'Price: Low to High',
  },
  price_high_to_low: {
    value: 'price_high_to_low',
    label: 'Price high to low',
    dropDown: 'Price: High to Low',
  },
  length_low_to_high: {
    value: 'length_low_to_high',
    label: 'Size small to large',
    dropDown: 'Size: Small to Large',
  },
  length_high_to_low: {
    value: 'length_high_to_low',
    label: 'Size large to small',
    dropDown: 'Size: Large to Small',
  },
};

export const MAX_BOATS = 306;
