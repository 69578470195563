import dayjs from 'dayjs';
import { DomesticV2BoatsIdCalendarGet200Response } from 'swagger/models';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line import/prefer-default-export
export const getNotAllowedDates =
  (isBookingMultiDay: boolean, dontFetch: boolean) => (boatCalendar: DomesticV2BoatsIdCalendarGet200Response) => {
    if (dontFetch) return [];

    const notAllowedDates = Object.entries(boatCalendar || {}).reduce((preValue, [key, value]: any) => {
      // if availability none or partial, not available for multiday
      if (isBookingMultiDay && (value.availability === 'none' || value.availability === 'partial')) {
        preValue.push(dayjs(key, 'YYYY-MM-DD').toDate());
      }
      if (!isBookingMultiDay && value.availability === 'none') {
        preValue.push(dayjs(key, 'YYYY-MM-DD').toDate());
      }
      return preValue;
    }, []);
    return notAllowedDates;
  };
