import { atom, Getter, Setter } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { atomWithReset, loadable, RESET } from 'jotai/utils';
import { SORT_OPTIONS } from 'utils/sort';
import {
  defaultParamValues,
  processQueryOut,
  processQueryOutApi,
  translateOldQueryParams,
} from 'stacks/search/queryHelpers';
import dayjs from 'dayjs';
import { sortObjectByObject } from 'components/search/v2/sortObjectByObject';
import axios from 'axios';
import { API_CLIENT_HEADERS } from 'api/OpenApiConfiguration';
import {
  AtomSliderObject,
  atomWithCheckboxObjectManipulator,
  atomWithRadioObjectManipulator,
  atomWithSliderObjectManipulator,
  atomWithToggle,
  getAtomCheckboxObject,
  getAtomRadioboxObject,
  isSliderFilterActiveAtom,
  notEmptyArrayAtom,
  notEmptyRadioAtom,
  SliderValueTuple,
} from './atomUtils';

export const defaultFilterValues = {
  PASSENGERS_COUNT: 4,
  MIN_PRICE: 0,
  MAX_PRICE: 1000,
  MIN_BOAT_LENGTH: 0,
  MAX_BOAT_LENGTH: 70,
  SORT_BY: SORT_OPTIONS.recommended.value,
  INSTANT_BOOK: false,
  SEARCH_MODE: '',
};

export const isRouterReadyAtom = atom(false);
export const isQuerySynchronizationDoneAtom = atom(false);

export const instantBookAtom = atomWithToggle(defaultFilterValues.INSTANT_BOOK);
export const passengersCountAtom = atomWithReset(defaultFilterValues.PASSENGERS_COUNT);

export const initialRouterStateAtom = atom({});

export const searchMapAsMoveAtom = atomWithToggle(true);
export const passengersFilterActiveAtom = atom(
  (get) => get(passengersCountAtom) !== defaultFilterValues.PASSENGERS_COUNT
);
export const sortByAtom = atom(defaultFilterValues.SORT_BY);

export const isMultiDayBookingAtom = atom(false);

export const tripDateBaseAtom = atom('');

// derived used to retrieve values
export const tripDateAtom = atom(
  (get) => {
    if (!get(isRouterReadyAtom)) {
      // router not ready - no date set
      return null;
    }

    if (get(tripDateBaseAtom) !== '') {
      // if already set the date => return date
      return get(tripDateBaseAtom);
    }

    return null;
  },
  (_get, set, date: string) => {
    set(tripDateBaseAtom, date);
    set(isMultiDayBookingAtom, false);
  }
);

export const multiDayStartDateAtom = atom(dayjs().add(3, 'day').format('YYYY-MM-DD'));
export const multiDayEndDateAtom = atom(dayjs().add(6, 'day').format('YYYY-MM-DD'));

export const boundingBoxAtom = atom({
  ne_lat: 0,
  ne_lng: 0,
  sw_lat: 0,
  sw_lng: 0,
});

export const placeBoundingBoxAtom = atom({
  ne_lat: 0,
  ne_lng: 0,
  sw_lat: 0,
  sw_lng: 0,
});

export const latLngPointAtom = atom({
  lat: 0,
  lng: 0,
});

const setSingleLatLngPointValueAtom = atom(null, (get, set, valueObject: any) =>
  set(latLngPointAtom, { ...get(latLngPointAtom), ...valueObject })
);

const setSingleBoundingBoxValueAtom = atom(null, (get, set, valueObject: any) =>
  set(boundingBoxAtom, { ...get(boundingBoxAtom), ...valueObject })
);

// sets place info, resets page and zoom + sets action to search
export const setPlaceFromSearchAtom = atom(null, (get, set, place: any) => {
  set(nearParamAtom, place.formatted_address);
  set(areaParamAtom, '');
  set(autoCompleteAtom, place);
  set(currentPageAtom, 1);
  set(searchActionAtom, 'search');

  const locationBounds = place.geometry.viewport;
  set(boundingBoxAtom, {
    ne_lat: locationBounds.getNorthEast().lat(),
    ne_lng: locationBounds.getNorthEast().lng(),
    sw_lat: locationBounds.getSouthWest().lat(),
    sw_lng: locationBounds.getSouthWest().lng(),
  });
  set(placeBoundingBoxAtom, {
    ne_lat: locationBounds.getNorthEast().lat(),
    ne_lng: locationBounds.getNorthEast().lng(),
    sw_lat: locationBounds.getSouthWest().lat(),
    sw_lng: locationBounds.getSouthWest().lng(),
  });
});

export const resetDSUAtom = atom(null, (get, set) => {
  set(otherQueryParamsAtom, {});
  set(locPhysicalMsAtom, '');
  set(locInterestMsAtom, '');
  set(semAccountIdAtom, '');
});

// sets place info, resets page and zoom + sets action to search
export const setPlaceFromLatLngPointAtom = atom(null, (get, set, { lat, lng, near }) => {
  set(nearParamAtom, near);
  set(currentPageAtom, 1);
  set(searchActionAtom, 'map');
  set(zoomLevelAtom, 11);
  set(latLngPointAtom, {
    lat,
    lng,
  });
});

export const setMapMoveAtom = atom(null, (get, set, { boundingBox: { ne_lat, ne_lng, sw_lat, sw_lng }, zoomLevel }) => {
  set(searchActionAtom, 'map');

  set(areaParamAtom, '');

  set(boundingBoxAtom, {
    ne_lat,
    ne_lng,
    sw_lat,
    sw_lng,
  });

  if (zoomLevel) {
    set(zoomLevelAtom, zoomLevel);
  }
});

export const setMultiDayBookingAtom = atom(
  null,
  (_get, set, { startDate, endDate }: { startDate: string; endDate: string }) => {
    set(multiDayStartDateAtom, startDate);
    set(multiDayEndDateAtom, endDate);
    set(isMultiDayBookingAtom, true);
  }
);

const bookingDateObjectAtom = atom((get) => {
  if (get(isMultiDayBookingAtom)) {
    return {
      start_date: get(multiDayStartDateAtom),
      end_date: get(multiDayEndDateAtom),
    };
  }
  return get(tripDateAtom)
    ? {
        trip_date: get(tripDateAtom),
      }
    : {};
});

export const zoomLevelAtom = atom(11);
export const currentPageAtom = atom(1);
export const nearParamAtom = atom('');
export const areaParamAtom = atom('');
export const autoCompleteAtom = atom({});
export const searchActionAtom = atom('search');

export const isMapVisibleAtom = atomWithToggle(true);
export const mapVisibilityStateStringAtom = atom(
  (get) => (get(isMapVisibleAtom) ? 'on' : 'off'),
  (get, set, state: 'on' | 'off') => set(isMapVisibleAtom, state === 'on')
);

export const hoveredBoatIdAtom = atom('');

export const perPageAtom = atom(18);

export const nearFromGeoTargetsAtom = atom((get) => {
  const geoTargets = get(geoTargetsAtom) as any;

  if (get(nearParamAtom)) return get(nearParamAtom);

  return geoTargets.state === 'hasData'
    ? `${geoTargets?.data?.canonical_name}, ${geoTargets?.data?.country_code}`
    : get(nearParamAtom);
});

const locPhysicalMsAtom = atom('');
const locInterestMsAtom = atom('');
export const semAccountIdAtom = atom('');
const searchModeAtom = atom(defaultFilterValues.SEARCH_MODE);

export const otherQueryParamsAtom = atom({});
export const isCaptainSearchModeAtom = atom((get) => get<any>(searchModeAtom) === 'captain_boat');

export const durationHoursAtom = atom([
  getAtomCheckboxObject('2 hours', '2 hours'),
  getAtomCheckboxObject('3 hours', '3 hours'),
  getAtomCheckboxObject('4 hours', '4 hours'),
  getAtomCheckboxObject('6 hours', '6 hours'),
  getAtomCheckboxObject('8 hours', '8 hours'),
]);
export const durationHoursManipulatorAtom = atomWithCheckboxObjectManipulator(durationHoursAtom);
export const isDurationFilterActiveAtom = notEmptyArrayAtom(durationHoursManipulatorAtom);

export const captainOptionsAtom = atom([
  getAtomRadioboxObject('captain', 'Captained'),
  getAtomRadioboxObject('no_captain', 'No captain'),
]);
export const captainOptionsManipulatorAtom = atomWithRadioObjectManipulator(captainOptionsAtom);
export const isCaptainFilterActiveAtom = notEmptyRadioAtom(captainOptionsManipulatorAtom);
export const checkedCaptainOptionsLabelAtom = atom((get) =>
  get(captainOptionsAtom)
    .filter(({ selected }) => selected)
    .map(({ label }) => label)
);

// Other filter START
export const exclusiveAtom = atomWithToggle(false);

export const priceAtom = atom<AtomSliderObject>({
  key: 'price',
  fieldType: 'range',
  min: defaultFilterValues.MIN_PRICE,
  max: defaultFilterValues.MAX_PRICE,
  label: 'Price per hour',
  unit: '$',
  step: 50,
  pushable: 49,
  valueAtom: atomWithReset<SliderValueTuple>([defaultFilterValues.MIN_PRICE, defaultFilterValues.MAX_PRICE]),
});
const priceObjectManipulatorAtom = atomWithSliderObjectManipulator(priceAtom);
export const isPriceFilterActiveAtom = isSliderFilterActiveAtom(priceAtom);

export const boatLengthAtom = atom<AtomSliderObject>({
  key: 'length',
  fieldType: 'range',
  min: defaultFilterValues.MIN_BOAT_LENGTH,
  max: defaultFilterValues.MAX_BOAT_LENGTH,
  label: 'Boat length',
  unit: "'",
  step: 5,
  pushable: 1,
  valueAtom: atomWithReset<SliderValueTuple>([
    defaultFilterValues.MIN_BOAT_LENGTH,
    defaultFilterValues.MAX_BOAT_LENGTH,
  ]),
});
const boatLengthManipulatorObjectAtom = atomWithSliderObjectManipulator(boatLengthAtom);
const isBoatLengthFilterActiveAtom = isSliderFilterActiveAtom(boatLengthAtom);

export const activitiesAtom = atom([
  getAtomCheckboxObject('cruising', 'Cruising'),
  getAtomCheckboxObject('fishing', 'Fishing'),
  getAtomCheckboxObject('watersports', 'Watersports'),
  getAtomCheckboxObject('celebrating', 'Celebrating'),
  getAtomCheckboxObject('sailing', 'Sailing'),
]);
export const activitiesManipulatorAtom = atomWithCheckboxObjectManipulator(activitiesAtom);
export const isActivityFilterActiveAtom = notEmptyArrayAtom(activitiesManipulatorAtom);

export const boatTypesAtom = atom([getAtomCheckboxObject('power', 'Power'), getAtomCheckboxObject('sail', 'Sail')]);
export const boatTypesManipulatorAtom = atomWithCheckboxObjectManipulator(boatTypesAtom);
export const isBoatTypeFilterActiveAtom = notEmptyArrayAtom(boatTypesManipulatorAtom);

export const boatMakesAtom = atom([
  getAtomCheckboxObject('Bayliner', 'Bayliner'),
  getAtomCheckboxObject('Beneteau', 'Beneteau'),
  getAtomCheckboxObject('Sea Ray', 'Sea Ray'),
  getAtomCheckboxObject('Yamaha', 'Yamaha'),
  getAtomCheckboxObject('Boston Whaler', 'Boston Whaler'),
  getAtomCheckboxObject('Catalina', 'Catalina'),
  getAtomCheckboxObject('Formula', 'Formula'),
  getAtomCheckboxObject('Azimut', 'Azimut'),
  getAtomCheckboxObject('Wellcraft', 'Wellcraft'),
]);
export const boatMakesManipulatorAtom = atomWithCheckboxObjectManipulator(boatMakesAtom);
export const isBoatMakeFilterActiveAtom = notEmptyArrayAtom(boatMakesManipulatorAtom);

const searchRadiusMaxDistance = atom(50);
const searchRadiusMinResults = atom(40);

export const boatCategoriesAtom = atom([
  getAtomCheckboxObject('airboat', 'Airboat'),
  getAtomCheckboxObject('aluminum_fishing', 'Aluminum fishing'),
  getAtomCheckboxObject('angler', 'Angler'),
  getAtomCheckboxObject('bass_boat', 'Bass boat'),
  getAtomCheckboxObject('bow_rider', 'Bow rider'),
  getAtomCheckboxObject('catamaran', 'Catamaran'),
  getAtomCheckboxObject('center_console', 'Center console'),
  getAtomCheckboxObject('classic', 'Classic'),
  getAtomCheckboxObject('commercial', 'Commercial'),
  getAtomCheckboxObject('convertible', 'Convertible'),
  getAtomCheckboxObject('cruiser', 'Cruiser'),
  getAtomCheckboxObject('cruiser_racer', 'Cruiser racer'),
  getAtomCheckboxObject('cuddy_cabin', 'Cuddy cabin'),
  getAtomCheckboxObject('cutter', 'Cutter'),
  getAtomCheckboxObject('daysailer_&_weekender', 'Daysailer & weekender'),
  getAtomCheckboxObject('deck_boat', 'Deck boat'),
  getAtomCheckboxObject('dive_boat', 'Dive boat'),
  getAtomCheckboxObject('downeast', 'Downeast'),
  getAtomCheckboxObject('dual_console', 'Dual console'),
  getAtomCheckboxObject('duck_boat', 'Duck boat'),
  getAtomCheckboxObject('electric', 'Electric'),
  getAtomCheckboxObject('express_cruiser', 'Express cruiser'),
  getAtomCheckboxObject('fish_and_ski', 'Fish and ski'),
  getAtomCheckboxObject('flats_boat', 'Flats boat'),
  getAtomCheckboxObject('flybridge', 'Flybridge'),
  getAtomCheckboxObject('houseboat', 'Houseboat'),
  getAtomCheckboxObject('inflatable', 'Inflatable'),
  getAtomCheckboxObject('inflatable_outboard', 'Inflatable outboard'),
  getAtomCheckboxObject('jet_boat', 'Jet Boat'),
  getAtomCheckboxObject('jet_ski_/_personal_water_craft', 'Jet Ski / PWC'),
  getAtomCheckboxObject('jon_boat', 'Jon boat'),
  getAtomCheckboxObject('ketch', 'Ketch'),
  getAtomCheckboxObject('mega_yacht', 'Mega yacht'),
  getAtomCheckboxObject('motorsailer', 'Motorsailer'),
  getAtomCheckboxObject('motor_yacht', 'Motor yacht'),
  getAtomCheckboxObject('offshore_sport_fishing', 'Offshore sport fishing'),
  getAtomCheckboxObject('other', 'Other'),
  getAtomCheckboxObject('performance', 'Performance'),
  getAtomCheckboxObject('performance_fishing', 'Performance fishing'),
  getAtomCheckboxObject('pilothouse', 'Pilothouse'),
  getAtomCheckboxObject('pontoon', 'Pontoon'),
  getAtomCheckboxObject('racer', 'Racer'),
  getAtomCheckboxObject('rigid_inflatable', 'Rigid inflatable'),
  getAtomCheckboxObject('runabout', 'Runabout'),
  getAtomCheckboxObject('saltwater_fishing', 'Saltwater fishing'),
  getAtomCheckboxObject('schooner', 'Schooner'),
  getAtomCheckboxObject('ski_and_wakeboard', 'Ski and wakeboard'),
  getAtomCheckboxObject('skiff', 'Skiff'),
  getAtomCheckboxObject('sloop', 'Sloop'),
  getAtomCheckboxObject('trawler', 'Trawler'),
  getAtomCheckboxObject('trimaran', 'Trimaran'),
  getAtomCheckboxObject('walkaround', 'Walkaround'),
  getAtomCheckboxObject('weekender', 'Weekender'),
  getAtomCheckboxObject('yawl', 'Yawl'),
]);
export const boatCategoriesManipulatorAtom = atomWithCheckboxObjectManipulator(boatCategoriesAtom);
export const isBoatCategoryFilterActiveAtom = notEmptyArrayAtom(boatCategoriesManipulatorAtom);

export const boatAmenitiesAtom = atom([
  getAtomCheckboxObject('bathroom', 'Bathroom'),
  getAtomCheckboxObject('bluetooth_audio', 'Bluetooth audio'),
  getAtomCheckboxObject('floating_mat', 'Floating mat'),
  getAtomCheckboxObject('grill', 'Grill'),
  getAtomCheckboxObject('gps', 'GPS'),
  getAtomCheckboxObject('refrigerator', 'Refrigerator'),
  getAtomCheckboxObject('shower', 'Shower'),
  getAtomCheckboxObject('wakeboard', 'Wakeboard'),
  getAtomCheckboxObject('floating_island', 'Inflatable toys'),
  getAtomCheckboxObject('paddleboards', 'Paddleboards'),
  getAtomCheckboxObject('snorkeling_gear', 'Snorkeling gear'),
  getAtomCheckboxObject('waterskis', 'Waterskis'),
  getAtomCheckboxObject('fish_finder', 'Fish finder'),
  getAtomCheckboxObject('fishing_gear', 'Fishing gear'),
  getAtomCheckboxObject('livewell_/_baitwell', 'Livewell / Baitwell'),
  getAtomCheckboxObject('childrens_life_jackets', 'Children’s life jacket'),
  getAtomCheckboxObject('swim_ladder', 'Swim ladder'),
  getAtomCheckboxObject('air_conditioning', 'Air conditioning'),
  getAtomCheckboxObject('cooler_/_ice_chest', 'Cooler / Ice chest'),
  getAtomCheckboxObject('stereo', 'Stereo'),
  getAtomCheckboxObject('wifi', 'Wifi'),
]);

export const boatAmenitiesManipulatorAtom = atomWithCheckboxObjectManipulator(boatAmenitiesAtom);
export const isBoatAmenitiesFilterActiveAtom = notEmptyArrayAtom(boatAmenitiesManipulatorAtom);

export const otherFiltersActiveCountAtom = atom(
  (get) =>
    [
      get(exclusiveAtom),
      get(isPriceFilterActiveAtom),
      get(isBoatLengthFilterActiveAtom),
      get(isActivityFilterActiveAtom),
      get(isBoatTypeFilterActiveAtom),
      get(isBoatMakeFilterActiveAtom),
      get(isBoatCategoryFilterActiveAtom),
      get(isBoatAmenitiesFilterActiveAtom),
    ].filter((active) => active === true).length
);

export const allFiltersActiveCountAtom = atom(
  (get) =>
    [
      get(isCaptainFilterActiveAtom),
      get(instantBookAtom),
      get(passengersFilterActiveAtom),
      get(exclusiveAtom),
      get(isPriceFilterActiveAtom),
      get(isBoatLengthFilterActiveAtom),
    ].filter((active) => active === true).length
);

export const areAnyOtherFiltersActiveAtom = atom((get) => get(otherFiltersActiveCountAtom) > 0);

export const resetOtherFiltersAtom = atom(null, (_get, set) => {
  set(exclusiveAtom, false);
  set(priceObjectManipulatorAtom, RESET);
  set(boatLengthManipulatorObjectAtom, RESET);
  set(activitiesManipulatorAtom, RESET);
  set(boatTypesManipulatorAtom, RESET);
  set(boatMakesManipulatorAtom, RESET);
  set(boatCategoriesManipulatorAtom, RESET);
  set(boatAmenitiesManipulatorAtom, RESET);
});
// Other filter END

export const isAnyFilterActiveAtom = atom((get) =>
  [
    get(isDurationFilterActiveAtom),
    get(isCaptainFilterActiveAtom),
    get(instantBookAtom),
    get(passengersFilterActiveAtom),
    get(areAnyOtherFiltersActiveAtom),
    get(isBoatAmenitiesFilterActiveAtom),
  ].some((val) => !!val)
);

export const resetAllFiltersAtom = atom(null, (_get, set) => {
  set(durationHoursManipulatorAtom, RESET);
  set(captainOptionsManipulatorAtom, RESET);
  set(instantBookAtom, defaultFilterValues.INSTANT_BOOK);
  set(passengersCountAtom, RESET);
  set(resetOtherFiltersAtom, RESET);
  set(sortByAtom, defaultFilterValues.SORT_BY);
  set(searchModeAtom, defaultFilterValues.SEARCH_MODE);
  set(durationHoursManipulatorAtom, RESET);
  set(captainOptionsManipulatorAtom, RESET);
  set(instantBookAtom, defaultFilterValues.INSTANT_BOOK);
  set(passengersCountAtom, RESET);
});

export const disableTrackEventsAtom = atom(false);

export const filterApiPayloadWithoutPageParamAtom = atom((get) => ({
  instant_bookable: get(instantBookAtom),
  passengers: get(passengersCountAtom),
  durations: get(durationHoursManipulatorAtom),
  package_types: get(captainOptionsManipulatorAtom),

  // other filters
  exclusive: get(exclusiveAtom),
  ...get(priceObjectManipulatorAtom),
  ...get(boatLengthManipulatorObjectAtom),
  activities: get(activitiesManipulatorAtom),
  boat_types: get(boatTypesManipulatorAtom),
  makes: get(boatMakesManipulatorAtom),
  boat_category: get(boatCategoriesManipulatorAtom),

  sort_by: get(sortByAtom),

  per_page: get(perPageAtom),
  search_mode: get(searchModeAtom),
  action: get(searchActionAtom),
  near: get(nearFromGeoTargetsAtom),
  area: get(areaParamAtom),
  max_distance: get(searchRadiusMaxDistance),
  min_results: get(searchRadiusMinResults),
  ...(get(searchActionAtom) === 'map' ? get(boundingBoxAtom) : {}),

  ...get(bookingDateObjectAtom),
  ...get(latLngPointAtom),

  loc_physical_ms: get(locPhysicalMsAtom),
  loc_interest_ms: get(locInterestMsAtom),
  sem_account_id: get(semAccountIdAtom),
}));

export type FilterApiPayloadWithoutPageType = ReturnType<typeof filterApiPayloadWithoutPageParamAtom['read']>;

export const filterApiPayloadAtom = atom((get) => {
  const basePayload = {
    ...get(filterApiPayloadWithoutPageParamAtom),
    features: get(boatAmenitiesManipulatorAtom)?.flatMap((value) => {
      if (value === 'shower') {
        return ['shower', 'deck_shower'];
      }
      if (value === 'inflatable_toys') {
        return ['floating_island', 'inflatable_toys'];
      }
      return value;
    }),
  };

  const cleanedPayload = Object.entries(basePayload).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {} as FilterApiPayloadWithoutPageType & { features: string[] });

  return {
    ...cleanedPayload,
    page: get(currentPageAtom),
    features: cleanedPayload.features || [],
  };
});

export type FilterApiPayloadType = ReturnType<typeof filterApiPayloadAtom['read']>;
export const paramsForQueryAtom = atom((get) => ({
  ...get(filterApiPayloadAtom),
  ...get(otherQueryParamsAtom),
  ...get(boundingBoxAtom),
  zoom_level: get(zoomLevelAtom),
  map_toggle: get(mapVisibilityStateStringAtom),
}));

const whiteListedDefaultInitParams = ['loc_physical_ms', 'sem_account_id', 'loc_physical_ms'];

export const filterQueryStringAtom = atom((get) => {
  const whiteListFromInit = Object.entries(get(initialRouterStateAtom))
    .filter(([key, value]) => whiteListedDefaultInitParams.includes(key) && defaultParamValues[key] === value)
    .map(([key]) => key);

  return processQueryOut(
    sortObjectByObject(get(paramsForQueryAtom), get(initialRouterStateAtom)),
    false,
    whiteListFromInit
  );
});

const getQueryToStateMapperObject = (_get: Getter, set: Setter) => ({
  // TODO maybe add parsers to numbers, booleans
  instant_bookable: (value) => set(instantBookAtom, value === 'true'),
  passengers: (value) => set(passengersCountAtom, Number(value) > 0 ? Number(value) : 4),
  durations: (values) => set(durationHoursManipulatorAtom, values),
  package_types: (values) => set(captainOptionsManipulatorAtom, values),

  // other filters
  exclusive: (value) => set(exclusiveAtom, value === 'true'),
  price_min: (value) => set(priceObjectManipulatorAtom, { value }),
  price_max: (value) => set(priceObjectManipulatorAtom, { value, isMaxValue: true }),
  length_min: (value) => set(boatLengthManipulatorObjectAtom, { value }),
  length_max: (value) => set(boatLengthManipulatorObjectAtom, { value, isMaxValue: true }),
  activities: (values) => set(activitiesManipulatorAtom, values),
  boat_types: (values) => set(boatTypesManipulatorAtom, values),
  makes: (values) => set(boatMakesManipulatorAtom, values),
  map_toggle: (value) => set(mapVisibilityStateStringAtom, value),
  boat_category: (values) => set(boatCategoriesManipulatorAtom, values),
  search_mode: (values) => set(searchModeAtom, values),
  max_distance: (values) => set(searchRadiusMaxDistance, values),
  min_results: (values) => set(searchRadiusMinResults, values),

  trip_date: (value) => {
    set(tripDateAtom, value);
    set(isMultiDayBookingAtom, false);
  },
  start_date: (value) => {
    set(multiDayStartDateAtom, value);
    set(isMultiDayBookingAtom, true);
  },
  end_date: (value) => set(multiDayEndDateAtom, value),

  sw_lng: (value) => set(setSingleBoundingBoxValueAtom, { sw_lng: value }),
  sw_lat: (value) => set(setSingleBoundingBoxValueAtom, { sw_lat: value }),
  ne_lng: (value) => set(setSingleBoundingBoxValueAtom, { ne_lng: value }),
  ne_lat: (value) => set(setSingleBoundingBoxValueAtom, { ne_lat: value }),

  lat: (value) => set(setSingleLatLngPointValueAtom, { lat: value }),
  lng: (value) => set(setSingleLatLngPointValueAtom, { lng: value }),

  sort_by: (value) => set(sortByAtom, value),
  zoom_level: (value) => set(zoomLevelAtom, parseInt(value, 10)),
  per_page: (value) => set(perPageAtom, parseInt(value, 10)),
  page: (value) => set(currentPageAtom, parseInt(value, 10)),
  near: (value) => set(nearParamAtom, value),
  area: (value) => set(areaParamAtom, value),
  action: (value) => set(searchActionAtom, value),
  loc_interest_ms: (value) => set(locInterestMsAtom, value),
  loc_physical_ms: (value) => set(locPhysicalMsAtom, value),
  sem_account_id: (value) => set(semAccountIdAtom, value),
  features: (values) => {
    const valueArray = Array.isArray(values) ? values : [values];
    const mappedValues = valueArray.flatMap((value) => {
      if (value === 'shower') {
        return ['shower', 'deck_shower'];
      }
      if (value === 'inflatable_toys') {
        return ['floating_island', 'inflatable_toys'];
      }
      return value;
    });

    set(boatAmenitiesManipulatorAtom, mappedValues);
  },
});

export const mergeExistingQueryToStateAtom = atom(null, (get, set, existingQueryObject = {}) => {
  const mapperObject = getQueryToStateMapperObject(get, set);
  Object.entries(translateOldQueryParams(existingQueryObject)).forEach(([key, value]) => {
    const mapFunction = mapperObject[key];
    if (mapFunction) {
      mapFunction(value);
    } else {
      set(otherQueryParamsAtom, {
        ...get(otherQueryParamsAtom),
        [key]: value,
      });
    }
  });
});

export const geoTargetsAtom = loadable(
  atomWithQuery((get) => ({
    queryKey: ['geoTargets', get(locPhysicalMsAtom), get(locInterestMsAtom), get(semAccountIdAtom)],
    queryFn: async ({ queryKey: [, locPhysicalMs, locInterestMs, semAccountId] }) => {
      const { data = {} } = await axios.get(`/domestic/v2/geo_targets/${locInterestMs || locPhysicalMs}`, {
        headers: API_CLIENT_HEADERS,
        params: semAccountId
          ? {
              sem_account_id: semAccountId,
            }
          : {},
      });
      return data;
    },
    // initialData: {},
    enabled: get(isRouterReadyAtom) && (!!get(locPhysicalMsAtom) || !!get(locInterestMsAtom)),
    refetchOnWindowFocus: false,
    retry: false,
  }))
);

export const DSUQueryNameAtom = atom(
  // @ts-ignore
  (get) => get(geoTargetsAtom)?.data?.canonical_name
);

export const filteredBoatsResponseAtom = loadable(
  atomWithQuery((get) => ({
    queryKey: ['filteredBoats', get(filterApiPayloadAtom)],
    queryFn: ({ queryKey: [, payload], signal }) =>
      axios.get('/domestic/v2/search', {
        params: processQueryOutApi(payload),
        headers: API_CLIENT_HEADERS,
        signal,
      }),
    keepPreviousData: true,
    enabled:
      get(isRouterReadyAtom) &&
      (!(!!get(locPhysicalMsAtom) || !!get(locInterestMsAtom)) ||
        ((!!get(locPhysicalMsAtom) || !!get(locInterestMsAtom)) && !!get(nearFromGeoTargetsAtom))),
    refetchOnWindowFocus: false,
  }))
);

export const previousBoatResponseDataAtom = atom<any>({
  state: 'loading',
  data: {},
});

export const areBoatsLoadingAtom = atom((get) => get(filteredBoatsResponseAtom).state === 'loading');

export const filteredBoatsResponseWithPreviousAtom = atom((get) => {
  const currentResponse = get(filteredBoatsResponseAtom);
  if (currentResponse.state === 'loading') {
    return get(previousBoatResponseDataAtom);
  }
  if (currentResponse.state === 'hasData') {
    return currentResponse;
  }
});

export const filteredBoatsAtom = atom((get) => {
  const response = get(filteredBoatsResponseWithPreviousAtom);
  const { data: { data: boats = [] } = {} } = response?.data ?? {};
  return boats;
});

export const filteredBoatsMetaAtom = atom((get) => {
  const response = get(filteredBoatsResponseWithPreviousAtom);
  const { data: { meta = {} } = {} } = response?.data ?? {};
  return meta;
});

export const totalBoatsCountAtom = atom((get) => get(filteredBoatsMetaAtom)?.total_count ?? 0);
