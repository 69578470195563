/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DomesticV2InsurersInsurerIdAnswersDelete200Response } from '../models';
// @ts-ignore
import { DomesticV2InsurersInsurerIdInsuranceStatusGet200Response } from '../models';
// @ts-ignore
import { DomesticV2InsurersInsurerIdRenterPoliciesPost201Response } from '../models';
// @ts-ignore
import { DomesticV2InsurersInsurerIdRenterPoliciesPostRequest } from '../models';
// @ts-ignore
import { DomesticV2MeInsurersGet200Response } from '../models';
// @ts-ignore
import { DomesticV3InsurersInsurerIdUnderwritingFieldsGet200Response } from '../models';
// @ts-ignore
import { InsuranceScreeningAnswers } from '../models';
// @ts-ignore
import { InsuranceScreeningAnswersDelete } from '../models';
// @ts-ignore
import { InsuranceScreeningQuestions } from '../models';
/**
 * InsurersApi - axios parameter creator
 * @export
 */
export const InsurersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswersDelete} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdAnswersDelete: async (insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswersDelete, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdAnswersDelete', 'insurerId', insurerId)
            const localVarPath = `/domestic/v2/insurers/{insurer_id}/answers`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswers} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdAnswersPut: async (insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdAnswersPut', 'insurerId', insurerId)
            const localVarPath = `/domestic/v2/insurers/{insurer_id}/answers`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdInsuranceStatusGet: async (insurerId: 'buoy' | 'boat_us', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdInsuranceStatusGet', 'insurerId', insurerId)
            const localVarPath = `/domestic/v2/insurers/{insurer_id}/insurance_status`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {Array<'owner' | 'renter' | 'boat'>} targetTypes The target type of the questions
         * @param {string} [boatId] The boat ID of the questions, required if target_types contains boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdQuestionsGet: async (insurerId: 'buoy' | 'boat_us', targetTypes: Array<'owner' | 'renter' | 'boat'>, boatId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdQuestionsGet', 'insurerId', insurerId)
            // verify required parameter 'targetTypes' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdQuestionsGet', 'targetTypes', targetTypes)
            const localVarPath = `/domestic/v2/insurers/{insurer_id}/questions`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (targetTypes) {
                localVarQueryParameter['target_types'] = targetTypes;
            }

            if (boatId !== undefined) {
                localVarQueryParameter['boat_id'] = boatId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new renter policy package id or booking id can pull all needed info if you have it
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {DomesticV2InsurersInsurerIdRenterPoliciesPostRequest} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdRenterPoliciesPost: async (insurerId: 'buoy' | 'boat_us', body?: DomesticV2InsurersInsurerIdRenterPoliciesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV2InsurersInsurerIdRenterPoliciesPost', 'insurerId', insurerId)
            const localVarPath = `/domestic/v2/insurers/{insurer_id}/renter_policies`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInsurersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/insurers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Boatsetter fields that Buoy requests.
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3InsurersInsurerIdUnderwritingFieldsGet: async (insurerId: 'buoy' | 'boat_us', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('domesticV3InsurersInsurerIdUnderwritingFieldsGet', 'insurerId', insurerId)
            const localVarPath = `/domestic/v3/insurers/{insurer_id}/underwriting_fields`
                .replace(`{${"insurer_id"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsurersApi - functional programming interface
 * @export
 */
export const InsurersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsurersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswersDelete} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2InsurersInsurerIdAnswersDelete(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswersDelete, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2InsurersInsurerIdAnswersDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2InsurersInsurerIdAnswersDelete(insurerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswers} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2InsurersInsurerIdAnswersPut(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceScreeningQuestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2InsurersInsurerIdAnswersPut(insurerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2InsurersInsurerIdInsuranceStatusGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {Array<'owner' | 'renter' | 'boat'>} targetTypes The target type of the questions
         * @param {string} [boatId] The boat ID of the questions, required if target_types contains boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2InsurersInsurerIdQuestionsGet(insurerId: 'buoy' | 'boat_us', targetTypes: Array<'owner' | 'renter' | 'boat'>, boatId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceScreeningQuestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2InsurersInsurerIdQuestionsGet(insurerId, targetTypes, boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new renter policy package id or booking id can pull all needed info if you have it
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {DomesticV2InsurersInsurerIdRenterPoliciesPostRequest} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId: 'buoy' | 'boat_us', body?: DomesticV2InsurersInsurerIdRenterPoliciesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2InsurersInsurerIdRenterPoliciesPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeInsurersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2MeInsurersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeInsurersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Boatsetter fields that Buoy requests.
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV3InsurersInsurerIdUnderwritingFieldsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsurersApi - factory interface
 * @export
 */
export const InsurersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsurersApiFp(configuration)
    return {
        /**
         * Delete the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswersDelete} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdAnswersDelete(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswersDelete, options?: any): AxiosPromise<DomesticV2InsurersInsurerIdAnswersDelete200Response> {
            return localVarFp.domesticV2InsurersInsurerIdAnswersDelete(insurerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Save the insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {InsuranceScreeningAnswers} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdAnswersPut(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswers, options?: any): AxiosPromise<InsuranceScreeningQuestions> {
            return localVarFp.domesticV2InsurersInsurerIdAnswersPut(insurerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId: 'buoy' | 'boat_us', options?: any): AxiosPromise<DomesticV2InsurersInsurerIdInsuranceStatusGet200Response> {
            return localVarFp.domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of insurance screening questions
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {Array<'owner' | 'renter' | 'boat'>} targetTypes The target type of the questions
         * @param {string} [boatId] The boat ID of the questions, required if target_types contains boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdQuestionsGet(insurerId: 'buoy' | 'boat_us', targetTypes: Array<'owner' | 'renter' | 'boat'>, boatId?: string, options?: any): AxiosPromise<InsuranceScreeningQuestions> {
            return localVarFp.domesticV2InsurersInsurerIdQuestionsGet(insurerId, targetTypes, boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new renter policy package id or booking id can pull all needed info if you have it
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {DomesticV2InsurersInsurerIdRenterPoliciesPostRequest} [body] Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId: 'buoy' | 'boat_us', body?: DomesticV2InsurersInsurerIdRenterPoliciesPostRequest, options?: any): AxiosPromise<DomesticV2InsurersInsurerIdRenterPoliciesPost201Response> {
            return localVarFp.domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInsurersGet(options?: any): AxiosPromise<DomesticV2MeInsurersGet200Response> {
            return localVarFp.domesticV2MeInsurersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Boatsetter fields that Buoy requests.
         * @param {'buoy' | 'boat_us'} insurerId The insurer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId: 'buoy' | 'boat_us', options?: any): AxiosPromise<DomesticV3InsurersInsurerIdUnderwritingFieldsGet200Response> {
            return localVarFp.domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsurersApi - interface
 * @export
 * @interface InsurersApi
 */
export interface InsurersApiInterface {
    /**
     * Delete the insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {InsuranceScreeningAnswersDelete} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2InsurersInsurerIdAnswersDelete(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswersDelete, options?: AxiosRequestConfig): AxiosPromise<DomesticV2InsurersInsurerIdAnswersDelete200Response>;

    /**
     * Save the insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {InsuranceScreeningAnswers} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2InsurersInsurerIdAnswersPut(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswers, options?: AxiosRequestConfig): AxiosPromise<InsuranceScreeningQuestions>;

    /**
     * 
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig): AxiosPromise<DomesticV2InsurersInsurerIdInsuranceStatusGet200Response>;

    /**
     * Get a list of insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {Array<'owner' | 'renter' | 'boat'>} targetTypes The target type of the questions
     * @param {string} [boatId] The boat ID of the questions, required if target_types contains boat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2InsurersInsurerIdQuestionsGet(insurerId: 'buoy' | 'boat_us', targetTypes: Array<'owner' | 'renter' | 'boat'>, boatId?: string, options?: AxiosRequestConfig): AxiosPromise<InsuranceScreeningQuestions>;

    /**
     * Create a new renter policy package id or booking id can pull all needed info if you have it
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {DomesticV2InsurersInsurerIdRenterPoliciesPostRequest} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId: 'buoy' | 'boat_us', body?: DomesticV2InsurersInsurerIdRenterPoliciesPostRequest, options?: AxiosRequestConfig): AxiosPromise<DomesticV2InsurersInsurerIdRenterPoliciesPost201Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV2MeInsurersGet(options?: AxiosRequestConfig): AxiosPromise<DomesticV2MeInsurersGet200Response>;

    /**
     * Returns the Boatsetter fields that Buoy requests.
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApiInterface
     */
    domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig): AxiosPromise<DomesticV3InsurersInsurerIdUnderwritingFieldsGet200Response>;

}

/**
 * InsurersApi - object-oriented interface
 * @export
 * @class InsurersApi
 * @extends {BaseAPI}
 */
export class InsurersApi extends BaseAPI implements InsurersApiInterface {
    /**
     * Delete the insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {InsuranceScreeningAnswersDelete} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2InsurersInsurerIdAnswersDelete(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswersDelete, options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2InsurersInsurerIdAnswersDelete(insurerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save the insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {InsuranceScreeningAnswers} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2InsurersInsurerIdAnswersPut(insurerId: 'buoy' | 'boat_us', body?: InsuranceScreeningAnswers, options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2InsurersInsurerIdAnswersPut(insurerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2InsurersInsurerIdInsuranceStatusGet(insurerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of insurance screening questions
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {Array<'owner' | 'renter' | 'boat'>} targetTypes The target type of the questions
     * @param {string} [boatId] The boat ID of the questions, required if target_types contains boat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2InsurersInsurerIdQuestionsGet(insurerId: 'buoy' | 'boat_us', targetTypes: Array<'owner' | 'renter' | 'boat'>, boatId?: string, options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2InsurersInsurerIdQuestionsGet(insurerId, targetTypes, boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new renter policy package id or booking id can pull all needed info if you have it
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {DomesticV2InsurersInsurerIdRenterPoliciesPostRequest} [body] Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId: 'buoy' | 'boat_us', body?: DomesticV2InsurersInsurerIdRenterPoliciesPostRequest, options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2InsurersInsurerIdRenterPoliciesPost(insurerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV2MeInsurersGet(options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV2MeInsurersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Boatsetter fields that Buoy requests.
     * @param {'buoy' | 'boat_us'} insurerId The insurer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurersApi
     */
    public domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId: 'buoy' | 'boat_us', options?: AxiosRequestConfig) {
        return InsurersApiFp(this.configuration).domesticV3InsurersInsurerIdUnderwritingFieldsGet(insurerId, options).then((request) => request(this.axios, this.basePath));
    }
}
