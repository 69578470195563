import { atom, Atom, WritableAtom } from 'jotai';
import { RESET } from 'jotai/utils';

export type SliderValueTuple = [number, number];

export type AtomCheckboxObject = {
  value: string;
  label: string;
  checkedAtom: WritableAtom<boolean, boolean | typeof RESET | undefined>;
};

export type AtomRadioObject = {
  value: string;
  label: string;
  selected: boolean;
};

export interface AtomSliderObject {
  key: string;
  fieldType: string;
  min: number;
  max: number;
  label: string;
  unit: string;
  step: number;
  pushable: number;
  valueAtom: WritableAtom<SliderValueTuple, SliderValueTuple | typeof RESET>;
}

export const getAtomCheckboxObject = (value, label, defaultChecked = false): AtomCheckboxObject => ({
  value,
  label,
  checkedAtom: atomWithToggle(defaultChecked),
});

export const getAtomRadioboxObject = (value, label, defaultSelected = false): AtomRadioObject => ({
  value,
  label,
  selected: defaultSelected,
});

export const notEmptyArrayAtom = (anAtom: Atom<any[]>) => atom((get) => get(anAtom).length > 0);
export const notEmptyRadioAtom = (anAtom: Atom<string[]>) =>
  atom((get) => {
    const selectedValues = get(anAtom);
    return selectedValues.length > 0;
  });

export const isSliderFilterActiveAtom = (anAtom: Atom<AtomSliderObject>) =>
  atom((get) => {
    const sliderAtomObject = get(anAtom);
    const [min, max] = get(sliderAtomObject.valueAtom);
    return min !== sliderAtomObject.min || max !== sliderAtomObject.max;
  });

export function atomWithToggle(initialValue?: boolean): WritableAtom<boolean, boolean | undefined> {
  const anAtom = atom(initialValue, (get, set, nextValue?: boolean) => {
    const update = nextValue ?? !get(anAtom);
    set(anAtom, update);
  });
  return anAtom as WritableAtom<boolean, boolean | undefined>;
}

export const atomWithCheckboxObjectManipulator = (
  anAtom: Atom<AtomCheckboxObject[]>
): WritableAtom<string[], string | string[] | typeof RESET | undefined> =>
  atom(
    (get) =>
      // used to get only checked values labels
      get(anAtom)
        .filter(({ checkedAtom }) => get(checkedAtom))
        .map(({ value }) => value),
    (get, set, values) => {
      if (values === RESET) {
        // used to reset state of whole checkbox filter
        get(anAtom).forEach(({ checkedAtom }) => set(checkedAtom, false));
      } else {
        // used to set state from default query upon loading page
        (typeof values === 'string' ? [values] : (values as string[]))?.forEach((value) => {
          const optionAtom = get(anAtom).find(({ value: optionValue }) => optionValue === value)?.checkedAtom;

          if (optionAtom) {
            set(optionAtom, true);
          }
        });
      }
    }
  );

export const atomWithRadioObjectManipulator = (
  anAtom: WritableAtom<AtomRadioObject[], AtomRadioObject[], void>
): WritableAtom<string[], string | string[] | typeof RESET | undefined, void> =>
  atom(
    (get) =>
      // used to get only selected values
      get(anAtom)
        .filter(({ selected }) => selected)
        .map(({ value }) => value),
    (get, set, values) => {
      const currentAtomValue = get(anAtom);

      if (values === RESET) {
        set(
          anAtom,
          currentAtomValue.map((item) => ({ ...item, selected: false }))
        );
      } else {
        const newValues = typeof values === 'string' ? [values] : (values as string[]);

        const updatedAtomValue = currentAtomValue.map((item) => ({
          ...item,
          selected: newValues.includes(item.value),
        }));

        set(anAtom, updatedAtomValue);
      }
    }
  );

export const atomWithSliderObjectManipulator = (anAtom: Atom<AtomSliderObject>) =>
  atom(
    (get) => {
      // used to get min, max object for query
      const sliderObject = get(anAtom);
      const [min, max] = get(sliderObject.valueAtom);
      return {
        [`${sliderObject.key}_min`]: min,
        [`${sliderObject.key}_max`]: max,
      };
    },
    (get, set, update: { value: number; isMaxValue?: boolean } | typeof RESET) => {
      if (update === RESET) {
        // used to reset to default values
        set(get(anAtom).valueAtom, RESET);
      } else {
        // used for setting the state of slider atom from query
        const { value, isMaxValue = false } = update;
        // TODO maybe parse value to number?
        const { valueAtom } = get(anAtom);
        const valueAtomValue = get(valueAtom);

        if (isMaxValue) {
          set(valueAtom, [valueAtomValue[0], value]);
        } else {
          set(valueAtom, [value, valueAtomValue[1]]);
        }
      }
    }
  );
