/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { mediaSmMax, mediaXsMax } from 'helpers/breakpoints';
import { FlexAlignCenter } from 'components/primitives/flex';
// Radio
export const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-top: 0.5px;
  margin-right: 40px;
  width: 22px;
  height: 22px;
  border: 1px solid #cccccc;
  border-radius: 100%;
  appearance: none;
  cursor: pointer;
  background-color: white;
  position: relative;
  outline: none;
  transition: all ease-in-out 0.2s;
  &.RadioOwner {
    border: 1px solid #8e9697;
  }

  &.RadioOwnerSecondary {
    background-color: #dbdfe5;
    border: 1px solid #8e9697;
  }

  &.RadioRegular {
    background-color: #dbdfe5;
    margin-right: 10px;
  }

  @media ${mediaSmMax} {
    margin-right: 16px;
  }

  &:checked {
    border: 1px solid #0751c2;
    border-radius: 100%;
    appearance: none;
    background-color: white;
    position: relative;
    outline: none;
    transition: all ease-in-out 0.2s;
    position: relative;
    &.RadioOwner {
      background-color: #0e7873;
      border: 1px solid #f4c064;
      &:disabled {
        border: 1px solid #8e9697;
        background-color: #dbdfe5;
        &::after {
          background-color: #8e9697;
        }
      }
      &::after {
        background-color: #f4c064;
      }
    }

    &.RadioOwnerSecondary {
      &::after {
        background-color: #0e7873;
      }
    }

    &::after {
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      background-color: #0751c2;
      transform: scale(1);
      transition: all ease-in-out 0.2s;
      border-radius: 100%;
      opacity: 1;
      transform: scale(1);
    }
  }

  &::before,
  &::after {
    margin: auto;
    content: '';
    display: block;
    position: absolute;
  }

  &::after {
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 8px;
    height: 8px;
    background-color: #0751c2;
    transform: scale(0.5);
    transition: all ease-in-out 0.2s;
    border-radius: 100%;
    &.RadioOwner {
      background-color: #f4c064;
      &:disabled {
        background-color: #8e9697;
      }
    }

    &.RadioOwnerSecondary {
      background-color: #0e7873;
    }
  }
`;

// Checkbox
export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<{ inputStyles?: string }>`
  ${(p) =>
    `width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    cursor: pointer;
    border: 1px solid #8e9697;
    border-radius: 3px;
    appearance: none;
    background-color: white;
    position: relative;
    outline: none;
    margin-right: 15px;
    transition: all ease-in-out 0.2s;
    ${p.inputStyles}

    &[disabled] {
      cursor: not-allowed;
    }

    @media ${mediaXsMax} {
      margin-right: 8px;
    }

    &.CheckboxOwner {
      border: 1px solid #8e9697;
    }

    &.CheckboxOwnerSecondary {
      border: 1px solid #8e9697;
    }

    &.CheckboxRegular {
      background-color: #dbdfe5;
    }

    &.Checkbox--small {
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
      border: 1px solid #8e9697;
    }
    &.big {
      margin-right: 32px;
    }

    &:checked {
      border: 1px solid #0751c2;
      appearance: none;
      transition: all ease-in-out 0.2s;

      + label {
        color: #0751c2;
      }

      &.CheckboxOwner {
        background-color: #0a5551;
        border: 1px solid #f4c064;

        &:checked::after {
          border-color: #f4c064;
        }

        + label {
          color: #0e7873;
        }
      }

      &.CheckboxOwnerSecondary {
        background-color: white;
        border: 1px solid #0e7873;
        &:checked::after {
          border-color: #0e7873;
        }
        + label {
          color: #0e7873;
        }
      }

      &::after {
        opacity: 0;
        top: 0;
        left: 1px;
        right: 0;
        bottom: 2px;
        -webkit-transition: -webkit-transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
        -webkit-transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
        transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
        border-style: solid;
        border-color: #0751c2;
        width: 5px;
        height: 12px;
        border-width: 0 2px 2px 0;
        -webkit-transform: scale(0.5) rotate(45deg);
        -ms-transform: scale(0.5) rotate(45deg);
        transform: scale(0.5) rotate(45deg);
        opacity: 1;
        width: 5px;
        height: 12px;
        border-width: 0 2px 2px 0;
        -webkit-transform: scale(1) rotate(45deg);
        -ms-transform: scale(1) rotate(45deg);
        transform: scale(1) rotate(45deg);
      }
    }

    &::before,
    &::after {
      margin: auto;
      content: '';
      display: block;
      position: absolute;
    }

    &::after {
      opacity: 0;
      top: 0;
      left: 1px;
      right: 0;
      bottom: 2px;
      -webkit-transition: -webkit-transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
      -webkit-transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
      transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
      border-style: solid;
      border-color: #0751c2;
      width: 5px;
      height: 12px;
      border-width: 0 2px 2px 0;
      -webkit-transform: scale(0.5) rotate(45deg);
      -ms-transform: scale(0.5) rotate(45deg);
      transform: scale(0.5) rotate(45deg);
      &.CheckboxOwner {
        border-color: #f4c064;
      }

      &.CheckboxOwnerSecondary {
        border-color: #0e7873;
      }
    }
  `}
`;

// InputText
export const InputTextFull = styled.div`
  width: 100%;
  &.InputTextBottomSpacing {
    margin-bottom: 32px;
  }
`;

export const InputTextContent = styled(FlexAlignCenter)`
  box-sizing: border-box;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
`;

export const TextInputCell = styled.div`
  box-sizing: inherit;
  flex: 0 0 100%;
  position: relative;
`;

export const InputTextWrapper = styled.div`
  position: relative;
`;

export const InputTextLabel = styled.label`
  transform: translate(12px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #5e696a;
  transform-origin: top left;

  &.InputTextLabelWithIcon {
    transform: translate(50px, 15px) scale(1);
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    color: #5e696a;
    transform-origin: top left;
    &.InputTextLabelIsFocused {
      transform: translate(50px, 5px) scale(0.75);
      color: #5e696a;
    }
  }

  &.InputTextLabelIsFocused {
    transform: translate(11px, 5px) scale(0.75);
    color: #5e696a;
  }
`;

export const InputTextPrefix = styled.label`
  transform: translate(12px, 23px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
  font-weight: 600;

  &.InputTextPrefixShow {
    opacity: 1;
    visibility: visible;
    transition: all 200ms ease;
  }
`;

export const InputTextPlaceholder = styled.div`
  transform: translate(11px, 22px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #8e9697;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;

  &.InputTextPlaceholderShow {
    opacity: 0.4;
    visibility: visible;
    transition: all 200ms ease;
  }
`;

export const TooltipWrapper = styled.span`
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 15px;
`;
