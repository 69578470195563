import { createMultiDayDurationOption } from 'components/forms/bookingWidget/utils';
import { Dayjs } from 'dayjs';
import { atom } from 'jotai';
import { BoatPackage } from 'swagger/models';
import { atomEmptyNull } from 'utils/atomUtils';

export const tripStartDayjsAtom = atomEmptyNull<Dayjs>();
export const tripStartFormattedAtom = atom((get) => get(tripStartDayjsAtom)?.format('YYYY-MM-DD'));

export const tripFinishDayjsAtom = atomEmptyNull<Dayjs>();
export const tripFinishFormattedAtom = atom((get) => get(tripFinishDayjsAtom)?.format('YYYY-MM-DD'));

export const bookingTripTimeAtom = atomEmptyNull<{
  label: string;
  value: string;
}>();
export const bookingDurationAtom = atomEmptyNull<ReturnType<typeof createMultiDayDurationOption>>();

export const isBookingInitializedAtom = atom<boolean>(false);
export const isBookingMultiDayAtom = atom<boolean>(false);

export const bookingBoatIdAtom = atomEmptyNull<string>();
export const selectedBookingPackageAtom = atomEmptyNull<BoatPackage>();
export const bookingPassengersAtom = atom<number>(1);
export const bookingMessageAtom = atomEmptyNull<string>();
export const bookingCustomPriceAtom = atomEmptyNull<number>();
export const bookingCaptainIdAtom = atomEmptyNull<string>();
export const bookingCaptainPublicIdAtom = atomEmptyNull<string>();

// trip start and finish from trips/calculator
export const tripStartCalculatedAtom = atomEmptyNull<string>();
export const tripFinishCalculatedAtom = atomEmptyNull<string>();
