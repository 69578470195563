/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DomesticV2BoatsBoatIdStartTimesPatchRequest } from '../models';
// @ts-ignore
import { StartTime } from '../models';
/**
 * StartTimeApi - axios parameter creator
 * @export
 */
export const StartTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update custom start times of a boat
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdStartTimesPatchRequest} [startTimes] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdStartTimesPatch: async (boatId: string, startTimes?: DomesticV2BoatsBoatIdStartTimesPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdStartTimesPatch', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/start_times`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startTimes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update start times of a boat to be default
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdStartTimesSetDefaultPatch: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdStartTimesSetDefaultPatch', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/start_times/set_default`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of global start times start 5 AM to 10 PM with default start from 8 AM to 6 PM and 30 minutes interval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2StartTimesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/start_times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartTimeApi - functional programming interface
 * @export
 */
export const StartTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StartTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * Update custom start times of a boat
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdStartTimesPatchRequest} [startTimes] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdStartTimesPatch(boatId: string, startTimes?: DomesticV2BoatsBoatIdStartTimesPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdStartTimesPatch(boatId, startTimes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update start times of a boat to be default
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of global start times start 5 AM to 10 PM with default start from 8 AM to 6 PM and 30 minutes interval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2StartTimesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StartTime>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2StartTimesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StartTimeApi - factory interface
 * @export
 */
export const StartTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StartTimeApiFp(configuration)
    return {
        /**
         * Update custom start times of a boat
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdStartTimesPatchRequest} [startTimes] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdStartTimesPatch(boatId: string, startTimes?: DomesticV2BoatsBoatIdStartTimesPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdStartTimesPatch(boatId, startTimes, options).then((request) => request(axios, basePath));
        },
        /**
         * Update start times of a boat to be default
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of global start times start 5 AM to 10 PM with default start from 8 AM to 6 PM and 30 minutes interval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2StartTimesGet(options?: any): AxiosPromise<Array<StartTime>> {
            return localVarFp.domesticV2StartTimesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StartTimeApi - interface
 * @export
 * @interface StartTimeApi
 */
export interface StartTimeApiInterface {
    /**
     * Update custom start times of a boat
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdStartTimesPatchRequest} [startTimes] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApiInterface
     */
    domesticV2BoatsBoatIdStartTimesPatch(boatId: string, startTimes?: DomesticV2BoatsBoatIdStartTimesPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update start times of a boat to be default
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApiInterface
     */
    domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns a list of global start times start 5 AM to 10 PM with default start from 8 AM to 6 PM and 30 minutes interval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApiInterface
     */
    domesticV2StartTimesGet(options?: AxiosRequestConfig): AxiosPromise<Array<StartTime>>;

}

/**
 * StartTimeApi - object-oriented interface
 * @export
 * @class StartTimeApi
 * @extends {BaseAPI}
 */
export class StartTimeApi extends BaseAPI implements StartTimeApiInterface {
    /**
     * Update custom start times of a boat
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdStartTimesPatchRequest} [startTimes] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApi
     */
    public domesticV2BoatsBoatIdStartTimesPatch(boatId: string, startTimes?: DomesticV2BoatsBoatIdStartTimesPatchRequest, options?: AxiosRequestConfig) {
        return StartTimeApiFp(this.configuration).domesticV2BoatsBoatIdStartTimesPatch(boatId, startTimes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update start times of a boat to be default
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApi
     */
    public domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId: string, options?: AxiosRequestConfig) {
        return StartTimeApiFp(this.configuration).domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of global start times start 5 AM to 10 PM with default start from 8 AM to 6 PM and 30 minutes interval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartTimeApi
     */
    public domesticV2StartTimesGet(options?: AxiosRequestConfig) {
        return StartTimeApiFp(this.configuration).domesticV2StartTimesGet(options).then((request) => request(this.axios, this.basePath));
    }
}
