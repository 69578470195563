import styled from 'styled-components';
import { FlexAlignStart } from 'components/primitives/flex';
import { ChangeEvent, ReactElement, Ref } from 'react';
import { CheckboxInput } from '../styles';

interface Props {
  label?: string | ReactElement;
  id?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: Ref<any>;
  owner?: boolean;
  ownerSecondary?: boolean;
  defaultChecked?: boolean;
  defaultValue?: string;
  labelStyle?: Record<string, unknown>;
  disabled?: boolean;
  extraStyles?: {
    wrapper?: string;
    input?: string;
    label?: string;
  };
}

const Checkbox = ({
  label,
  name,
  id = 'checkbox1',
  onChange = () => null,
  inputRef,
  owner = false,
  ownerSecondary = false,
  defaultValue,
  defaultChecked,
  labelStyle,
  disabled = false,
  extraStyles = { label: '' },
}: Props & Record<string, unknown>) => (
  <CheckboxWrapper wrapperStyles={extraStyles.wrapper}>
    <CheckboxInput
      data-testid={name}
      ref={inputRef}
      id={id}
      name={name}
      onChange={onChange}
      defaultChecked={defaultChecked}
      defaultValue={defaultValue}
      disabled={disabled}
      className={`${owner ? 'CheckboxOwner CheckboxRegular' : ''} ${ownerSecondary ? 'CheckboxOwnerSecondary' : ''}`}
      inputStyles={extraStyles.input}
    />
    {label && (
      <Label style={labelStyle} className={owner ? 'LabelOwners' : ''} htmlFor={id} labelStyles={extraStyles.label}>
        {label}
      </Label>
    )}
  </CheckboxWrapper>
);

export default Checkbox;

const CheckboxWrapper = styled(FlexAlignStart)<{ wrapperStyles: string }>`
  ${(p) => p.wrapperStyles}
`;

const Label = styled.label<{ labelStyles: string }>`
  ${(p) =>
    `&.LabelOwners {
      font-size: 16px;
      font-weight: 600;
      ${p.labelStyles}
    }`}
`;
