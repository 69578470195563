import { mediaMLgMin, mediaMMdMax, mediaMMdMin, mediaMSmMax } from 'helpers/breakpoints';
import { css, CSSProperties } from 'styled-components';

interface GapProps {
  gap?: string;
  gapMdMax?: string;
}

export interface GapPropsResponsive extends GapProps {
  mdMin?: GapProps;
}

export const gap = css<GapPropsResponsive>`
  gap: ${({ gap: gapProp }) => gapProp};

  ${mediaMMdMax} {
    /* TODO invert to mobile first, fix where changed, change to mdMin object */
    gap: ${({ gapMdMax }) => gapMdMax};
  }
  ${mediaMMdMin} {
    gap: ${({ mdMin }) => mdMin?.gap};
  }
`;

interface SizeProp {
  size?: string;
}
export const uniformSizing = css<SizeProp>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

export interface SizingProps {
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
}
export interface MarginProps {
  margin?: string;
  mx?: string;
  my?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
}
export interface PaddingProps {
  padding?: string;
  px?: string;
  py?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
}

export interface BorderProps {
  border?: CSSProperties['border'];
  borderTop?: CSSProperties['borderTop'];
  borderBottom?: CSSProperties['borderBottom'];
  borderLeft?: CSSProperties['borderLeft'];
  borderRight?: CSSProperties['borderRight'];
  borderRadius?: CSSProperties['borderRadius'];
}

export interface BorderPropsResponsive extends BorderProps {
  mdMin?: BorderProps;
  lgMin?: BorderProps;
  smMax?: BorderProps;
}

export interface SizingPropsResponsive extends SizingProps {
  mdMin?: SizingProps;
  lgMin?: SizingProps;
}
export interface MarginPropsResponsive extends MarginProps {
  mdMin?: MarginProps;
  lgMin?: MarginProps;
  smMax?: MarginProps;
}
export interface PaddingPropsResponsive extends PaddingProps {
  mdMin?: PaddingProps;
  lgMin?: PaddingProps;
  smMax?: PaddingProps;
}

const buildBorderCSS = ({ border, borderTop, borderLeft, borderRight, borderBottom, borderRadius }: BorderProps) => css`
  border: ${border};
  border-top: ${borderTop};
  border-bottom: ${borderBottom};
  border-left: ${borderLeft};
  border-right: ${borderRight};
  border-radius: ${borderRadius};
`;

const buildSizingCSS = ({ width, height, minWidth, maxWidth, minHeight, maxHeight }: SizingProps) => css`
  width: ${width};
  height: ${height};
  min-width: ${minWidth};
  max-width: ${maxWidth};
  min-height: ${minHeight};
  max-height: ${maxHeight};
`;

const buildMarginCSS = ({ margin, mx, my, mt, mb, ml, mr }: MarginProps) => css`
  margin: ${margin};
  margin: ${mx && `0 ${mx}`};
  margin: ${my && `${my} 0`};
  margin-top: ${mt};
  margin-bottom: ${mb};
  margin-left: ${ml};
  margin-right: ${mr};
`;

const buildPaddingCSS = ({ padding, px, py, pt, pb, pl, pr }: PaddingProps) => css`
  padding: ${padding};
  padding: ${px && `0 ${px}`};
  padding: ${py && `${py} 0`};
  padding-top: ${pt};
  padding-bottom: ${pb};
  padding-left: ${pl};
  padding-right: ${pr};
`;

export const border = css<BorderPropsResponsive>`
  ${(props) => buildBorderCSS(props)}

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildBorderCSS(mdMin)}
  }
  ${mediaMLgMin} {
    ${({ lgMin = {} }) => buildBorderCSS(lgMin)}
  }
  ${mediaMSmMax} {
    ${({ smMax = {} }) => buildBorderCSS(smMax)}
  }
`;

export const sizing = css<SizingPropsResponsive>`
  ${(props) => buildSizingCSS(props)}

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildSizingCSS(mdMin)}
  }
  ${mediaMLgMin} {
    ${({ lgMin = {} }) => buildSizingCSS(lgMin)}
  }
`;

export const margin = css<MarginPropsResponsive>`
  ${(props) => buildMarginCSS(props)}

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildMarginCSS(mdMin)}
  }
  ${mediaMLgMin} {
    ${({ lgMin = {} }) => buildMarginCSS(lgMin)}
  }
  ${mediaMSmMax} {
    ${({ smMax = {} }) => buildMarginCSS(smMax)}
  }
`;

export const padding = css<PaddingPropsResponsive>`
  ${(props) => buildPaddingCSS(props)}

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildPaddingCSS(mdMin)}
  }
  ${mediaMLgMin} {
    ${({ lgMin = {} }) => buildPaddingCSS(lgMin)}
  }
  ${mediaMSmMax} {
    ${({ smMax = {} }) => buildPaddingCSS(smMax)}
  }
`;
