import Bugsnag from '@bugsnag/js';
import queryString from 'query-string';
import dayjs from 'dayjs';

export const qsOptions = {
  arrayFormat: 'none',
  parseNumbers: true,
  parseBooleans: true,
  encode: false,
  sort: false,
  decode: true,
};

export const constructQueryStringWithoutEncode = (queryObject = {}): string =>
  queryString.stringify(
    Object.entries(queryObject).reduce((acc, [k, v]) => {
      if (v !== 'null' && !`${v}`.includes('NaN') && v !== 'undefined') {
        acc[k] = v;
      }
      return acc;
    }, {}),
    // @ts-ignore
    qsOptions
  );

// remove undefined null etc
export const cleanQuery = (payload = {}) =>
  Object.entries(payload).reduce((acc, [k, v]) => {
    if (
      v &&
      typeof v !== 'undefined' &&
      v !== null &&
      v !== 'undefined' &&
      k !== 'active' &&
      v !== 'NaN' &&
      v !== 'NaN,NaN' &&
      v !== '[object Object]'
    ) {
      if (Array.isArray(v) && v.length) {
        acc[k] = v;
      } else {
        try {
          acc[k] = decodeURIComponent((v ? `${v}` : '').replace(/\+/g, ' '));
        } catch (err) {
          Bugsnag.notify(err);
          console.error(err);
        }
      }
    }
    return acc;
  }, {});

export const defaultParamValues = {
  length_min: 0,
  length_max: 70,
  passengers: 4,
  price_min: 0,
  price_max: 1000,
  instant_bookable: false,
  exclusive: false,
  makes: {},
  activities: {},
  boat_category: {},
  boat_types: {},
  package_types: {},
  durations: {},
  lat: '0',
  lng: '0',
  loc_interest_ms: '',
  sem_account_id: '',
  loc_physical_ms: '',
  ne_lat: 0,
  ne_lng: 0,
  sw_lng: 0,
  sw_lat: 0,
  max_distance: 50,
  min_results: 40,
};

const paramsWhitelist = [
  'length_min',
  'zoom_level',
  'length_max',
  'passengers',
  'exclusive',
  'price_min',
  'price_max',
  'instant_bookable',
  'trip_date',
  'boat_category',
  'categories',
  'makes',
  'durations',
  'duration',
  'package_types',
  'package_type',
  'make',
  'duration',
  'boat_types',
  'boat_type',
  'activities',
  'min_passengers',
  'sort_by',
  'lat',
  'lng',
  'lat_lng_ne',
  'lat_lng_sw',
  'debug',
  'sw_lat',
  'sw_lng',
  'map_toggle',
  'ne_lat',
  'ne_lng',
  'admin_level_one',
  'country',
  'locality',
  'captain_statuses',
  'admin_level_one_short',
  'country_short',
  'locality_short',
  'page',
  'per_page',
  'action',
  'near',
  'search_mode',
  'sem_account_id',
  'loc_physical_ms',
  'loc_interest_ms',
  'max_distance',
  'min_results',
  'features',
  // UTM stuff DTU changes
  'sem_campaign_id',
  'sem_ad_group_id',
  'sem_device_type',
  'sem_keyword',
  'sem_matchtype',
  'sem_ad_id',
  'sem_network',
  'sem_target_id',
  'sem_feed_item_id',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
  'sem_location_id',
  'sem_placement',
  'sem_placement_category',
  'sem_location_interest_id',
  'gclid',
  'start_date',
  'end_date',
];

export const removeUnusedParams = (query) => {
  const q = Object.entries(query).reduce((acc, [k, v]) => {
    if (paramsWhitelist.includes(k)) {
      acc[k] = v;
    }
    return acc;
  }, {});

  return q;
};
export const removeDefaultQueryParams = (query, whitelist = []) => {
  const q = Object.entries(query).reduce((acc, [k, v]) => {
    // eslint-disable-next-line no-empty
    if (defaultParamValues[k] !== undefined && `${v}` === `${defaultParamValues[k]}` && !whitelist.includes(k)) {
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});

  return q;
};

const oldParams = {
  latLngNe: 'lat_lng_ne',
  latLngSw: 'lat_lng_sw',
  start_period: 'trip_date',
  end_period: 'trip_end_date',
  package_type: 'package_types',
};

export const translateOldQueryParams = (q) =>
  Object.entries(q).reduce((acc: any, [k, v]) => {
    if (Object.keys(oldParams).includes(k)) {
      if ((oldParams[k] === 'trip_date' || oldParams[k] === 'trip_end_date') && v) {
        // @ts-ignore
        acc[oldParams[k]] = dayjs(decodeURIComponent(v), 'MM-DD-YYYY').format('YYYY-MM-DD');
      } else if (oldParams[k] === 'package_types') {
        if (v === 'captained') {
          acc[oldParams[k]] = 'captain';
        } else if (v === 'bareboat') {
          acc[oldParams[k]] = 'no_captain';
        }
      } else {
        acc[oldParams[k]] = v;
      }
    } else {
      acc[k] = v;
    }
    if (q.sort_by) {
      acc.sort_by = q.sort_by;
    }
    return acc;
  }, {});

const newParams = {
  duration: 'durations',
  package_types: 'captain_statuses',
  passengers: 'min_passengers',
  boat_category: 'categories',
  makes: 'manufacturers',
};
export const translateQueryParamsOut = (q) =>
  Object.entries(q).reduce((acc, [k, v]) => {
    if (Object.keys(newParams).includes(k) && k !== 'passengers') {
      acc[newParams[k]] = v;
    } else if (Object.keys(newParams).includes(k)) {
      acc[newParams[k]] = v;
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});

export const processQueryIn = (query) => translateOldQueryParams(cleanQuery(query));

export const processQueryOut = (query, dontStringify = false, whitelist: string[] = []) => {
  const params = removeDefaultQueryParams(query, whitelist);
  // const params =  removeUnusedParams(cleanQuery((query)));;

  const p = dontStringify ? params : constructQueryStringWithoutEncode(params);
  return p;
};

export const constructPdpQuery = (query) => {
  const payload: any = {};

  const transformSearchToPdp = {
    no_captain: 'bareboat',
    captain: 'captained',
    '4 hours': 'half_day',
    '2 hours': 'two_hours',
    '3 hours': 'three_hours',
    '6 hours': 'six_hours',
    '8 hours': 'full_day',
  };

  const { passengers, trip_date, package_types, durations, start_date, end_date } = query;

  if (passengers) {
    payload.passengers = passengers;
  }
  if (trip_date) {
    payload.start_period = dayjs(trip_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }
  if (start_date && end_date) {
    payload.start_period = dayjs(start_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    payload.end_period = dayjs(end_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }
  if (package_types?.length === 1) {
    const selectedType = package_types[0];
    payload.package_type = transformSearchToPdp[selectedType];
  }
  if (!Array.isArray(durations)) {
    payload.duration = transformSearchToPdp[durations];
  }
  return payload;
};

export const processQueryOutPdp = (query) => {
  const params = constructQueryStringWithoutEncode(constructPdpQuery(cleanQuery(query)));

  return params;
};

export const processQueryOutApi = (query) => {
  const queryOut = { ...query };
  // queryOut.min_passengers = query.passengers;
  if (query.duration) {
    queryOut.duration = query.duration === 'all_day' ? 'full_day' : query.duration;
  }
  // if (query.action !== 'map') {
  //   delete queryOut.ne_lat;
  //   delete queryOut.ne_lng;
  //   delete queryOut.sw_lat;
  //   delete queryOut.sw_lng;
  // }

  if (query.lng === 0 || query.lng === '0') delete queryOut.lng;
  if (query.lat === 0 || query.lat === '0') delete queryOut.lat;

  delete queryOut.map_toggle;

  if (query.area) {
    queryOut.near = query.area;
  }

  return translateQueryParamsOut(removeUnusedParams(cleanQuery(removeDefaultQueryParams(queryOut))));
};

export const qsArgs = [
  // @ts-ignore
  (v) => queryString.stringify(v, qsOptions),
  // @ts-ignore
  (v) => processQueryIn(queryString.parse(v, qsOptions)),
];
