import { getAsNumber, getAsString } from 'helpers';
import dayjs from 'dayjs';
import { ParsedUrlQuery } from 'querystring';
import { BoatAddOn, Booking, BookingAddOn } from 'swagger/models';

export const computeTotalPrice = (priceList: number[]) => priceList.reduce((prev, current) => prev + current, 0);

export const getCalculatorPricingFromURL = (query: ParsedUrlQuery) => ({
  package_id: getAsString(query['booking[package_public_id]']),
  package_public_id: getAsString(query['booking[package_public_id]']),
  duration: getAsString(query['booking[duration]']) as any,
  trip_start: dayjs(getAsString(query['booking[trip_start]'])).format('YYYY-MM-DD'),
  trip_time: getAsString(query['booking[trip_time]']),
  tz: getAsString(query['booking[tz]']),
  package_type: getAsString(query['booking[package_type]']),
  trip_finish: dayjs(getAsString(query['booking[trip_finish]'])).format('YYYY-MM-DD'),
  passengers: getAsNumber(query['booking[passengers]']),
  captain_public_id: getAsString(query['booking[captain_public_id]']) || undefined,
  captain_id: getAsString(query['booking[captain_public_id]']) || undefined,
});

type ParamsFromUrl = {
  boat_public_id: string;
  booking_id?: string;
  trip_start: string;
  trip_finish: string;
  package_id?: string;
  passengers: number;
  duration: string;
  package_public_id: string;
  trip_time: string;
  package_type: string;
  captain_public_id: string;
  captain_id: string;
};

export const getParamsForScreeningQuestions = (obj: ParamsFromUrl) => {
  const { passengers } = obj;

  // remove undefined values
  const params = Object.keys(obj).reduce(
    (acc, key) => (obj[key] === undefined ? { ...acc } : { ...acc, [key]: obj[key] }),
    {} as ParamsFromUrl
  );

  // package id not required for screening questionspage
  delete params.package_id;
  return {
    ...params,
    num_passengers: passengers,
  };
};

export const getBookingParamsForScreeningQuestions = (booking: Booking) => ({
  booking_id: booking?.id,
  boat_public_id: booking?.boat?.id,
  trip_start: dayjs(booking?.trip_start).utc().format(),
  trip_finish: dayjs(booking?.trip_finish).utc().format(),
  num_passengers: booking?.passengers,
  duration: booking?.trip_type,
  package_public_id: booking?.package?.id,
});

type FormAddon = {
  enabled: boolean;
  quantity: number;
  boat_add_on_id: number;
};

export const transformFormAddonDataToAPIAddonsData = (addons: FormAddon[], allowedAddons: BoatAddOn[] = []) => {
  const allowedIds = allowedAddons?.map((e) => e.id);

  return addons
    .filter((e) => allowedIds.includes(Number(e.boat_add_on_id)))
    .filter((a) => a.enabled)
    .map(({ boat_add_on_id, quantity }) => ({
      boat_add_on_id: Number(boat_add_on_id),
      quantity: Number(quantity),
    }));
};

export type TransformedAddonsToApiType = ReturnType<typeof transformFormAddonDataToAPIAddonsData>;

export const transformBookingAddonsToFormAddonData = (addons: BookingAddOn[]): FormAddon[] =>
  addons.map((a) => ({
    boat_add_on_id: a.id,
    enabled: true,
    quantity: a.quantity,
  }));
