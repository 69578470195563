import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { useDefaultStartTimes, useTripCalculator } from 'hooks/queries';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DomesticV2BoatsIdCalendarGet200Response } from 'swagger/models';
import { BoatsApi } from 'swagger/apis/boats-api';
import { getAsString, valueEmpty } from 'helpers';
import {
  apiHourNameMap,
  apiParamsHourNameMapReverse,
  createDurationOption,
  createMultiDayDurationOption,
} from 'components/forms/bookingWidget/utils';
import { useRouter } from 'next/router';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import {
  bookingBoatIdAtom,
  bookingDurationAtom,
  bookingPassengersAtom,
  selectedBookingPackageAtom,
  tripFinishDayjsAtom,
  tripStartDayjsAtom,
  bookingTripTimeAtom,
  isBookingInitializedAtom,
  isBookingMultiDayAtom,
} from './jotaiStore';

const boatApi = new BoatsApi(OpenApiConfiguration);

export function useBoatCalendar(
  {
    startOn,
    endOn,
    boatId,
    packageType,
    bookingId,
  }: { startOn: string; endOn: string | null; boatId: string; packageType?: string; bookingId?: string },
  { select, enabled: customEnabled = true }: { select?: any; enabled?: boolean } = {}
) {
  const [allRecords, setAllRecords] = useState<DomesticV2BoatsIdCalendarGet200Response>({});
  const defaultEndOn = !endOn ? dayjs(startOn).add(1, 'month').format('YYYY-MM-DD') : endOn;

  const isBookingMultiDay = useAtomValue(bookingDurationAtom);

  const dayJsStart = dayjs(startOn, 'YYYY-MM-DD');
  const dayJsEnd = dayjs(defaultEndOn, 'YYYY-MM-DD');

  const tripDays = dayJsEnd?.diff(dayJsStart, 'day');
  const daysMoreThanMonth = tripDays > 31;

  // if the trip is more than 31 days, we need to set the endOn to the end of the month to not
  // pass the limit of the api
  const startDate = daysMoreThanMonth ? dayJsStart?.startOf('month')?.format('YYYY-MM-DD') : startOn;
  const endDate = daysMoreThanMonth ? dayJsStart?.add(1, 'month')?.endOf('month')?.format('YYYY-MM-DD') : defaultEndOn;

  const { data: { data: boatCalendar = {} } = {}, ...others } = useQuery(
    ['boatCalendar', boatId, startDate, endDate, bookingId, packageType ?? 'bareboat', isBookingMultiDay],
    () => boatApi.domesticV2BoatsIdCalendarGet(boatId, startDate, endDate, bookingId, packageType ?? 'bareboat'),
    {
      enabled: customEnabled && !!boatId && !!startOn,
      select,
      keepPreviousData: true,
    }
  );
  useEffect(() => {
    setAllRecords({ ...allRecords, ...boatCalendar });
  }, [JSON.stringify(boatCalendar)]);

  return { boatCalendar: allRecords, ...others };
}

export const useInitializeBookingFormData = (boatId: string, primaryPackage) => {
  const [isBookingInitialized, setIsBookingInitialized] = useAtom(isBookingInitializedAtom);
  const setPassengersCount = useSetAtom(bookingPassengersAtom);
  const setBookingBoatId = useSetAtom(bookingBoatIdAtom);
  const setIsBookingMultiDay = useSetAtom(isBookingMultiDayAtom);
  const setSelectedBookingPackage = useSetAtom(selectedBookingPackageAtom);
  const setBookingDuration = useSetAtom(bookingDurationAtom);
  const setTripFinish = useSetAtom(tripFinishDayjsAtom);

  const { defaultStartTimes } = useDefaultStartTimes();
  const { boatDetails } = useBoatDetails(boatId);

  const router = useRouter();
  const { start_period: routerQueryStartPeriod } = router.query;

  // initial data fetch
  useEffect(() => {
    (async () => {
      if (isBookingInitialized) return;

      setBookingBoatId(boatId);

      if (!!router.query.end_period && !!primaryPackage?.all_day_cents) {
        setIsBookingMultiDay(true);
        setTripFinish(dayjs(router.query.end_period as string));
      }

      if (defaultStartTimes) {
        setSelectedBookingPackage(primaryPackage);
        if (router.query.end_period) {
          setBookingDuration(
            createMultiDayDurationOption(router.query.end_period || dayjs(), dayjs(getAsString(routerQueryStartPeriod)))
          );
        } else {
          const queryDuration = router.query.duration as string;
          const hourInt = apiParamsHourNameMapReverse[queryDuration];
          if (hourInt && primaryPackage[apiHourNameMap[hourInt]]) {
            setBookingDuration(createDurationOption(apiParamsHourNameMapReverse[queryDuration], queryDuration));
          } else {
            const duration = apiParamsHourNameMapReverse?.[boatDetails?.cheapest_package?.price_name];
            if (duration)
              setBookingDuration(
                createDurationOption(
                  duration,
                  apiHourNameMap?.[apiParamsHourNameMapReverse?.[boatDetails?.cheapest_package?.price_name]]
                )
              );
          }
        }

        let passengers = Number(router.query.passengers);
        passengers = passengers
          ? Math.min(passengers < 1 ? 4 : passengers, boatDetails.capacity)
          : Math.min(boatDetails.capacity, 4);

        setPassengersCount(passengers);
      }

      setIsBookingInitialized(!valueEmpty(boatDetails));
    })();
  }, [defaultStartTimes, boatDetails, isBookingInitialized, routerQueryStartPeriod]);
};

export const useRudderStackOrderTraits = () => {
  const { tripCalculation } = useTripCalculator();

  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const bookingDuration = useAtomValue(bookingDurationAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const passengersCount = useAtomValue(bookingPassengersAtom);
  const tripTime = useAtomValue(bookingTripTimeAtom);
  const tripStart = useAtomValue(tripStartDayjsAtom);
  const tripFinish = useAtomValue(tripFinishDayjsAtom);

  return {
    instant_bookable: selectedBookingPackage?.instant_bookable,
    boat_id: bookingBoatId,
    duration: bookingDuration?.value === -1 ? 'multiday' : bookingDuration?.value,
    package_type: selectedBookingPackage?.package_type,
    passengers: passengersCount,
    start_time: tripTime?.value,
    trip_start: tripStart,
    trip_finish: tripFinish,
    price: (tripCalculation?.boat_price || 0) + (tripCalculation?.captain_fee || 0),
  };
};
