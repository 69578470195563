import { atom } from 'jotai';
import { atomEmptyNull } from 'utils/atomUtils';
import { ReactNode } from 'react';
import { Booking, TripCalculatorInsuranceTypeEnum } from 'swagger/models';

interface TripCalculation extends Booking {
  insurance_type?: string | TripCalculatorInsuranceTypeEnum;
}

export const isInquiryAtom = atom<boolean>(false);
export const boatIdAtom = atom<string>('');
export const bookingIdAtom = atom<string>('');

export const isPromoCodeAppliedAtom = atom<boolean>(false);
export const promoCodeValueAtom = atomEmptyNull<string>();
export const promoCodeErrorAtom = atom<boolean>(false);

export const selectedCreditCardAtom = atomEmptyNull<{ value: string; label: ReactNode }>();
export const isAddingNewCardAtom = atom<boolean>(false);
export const tripCalculationAtom = atom<TripCalculation>({});
export const existingBookingAtom = atom<Booking>({});

export const isBuoyBookingAtom = atom<boolean>(false);
