import { atom } from 'jotai';

export const EMPTY = Symbol('Describes empty atom');

export const atomEmpty = <Value>() => atom<Value | typeof EMPTY>(EMPTY);

export const atomEmptyNull = <ValueType>() => {
  const emptyAtom = atomEmpty<ValueType>();
  const emptyNullAtom = atom<ValueType | null, ValueType | typeof EMPTY>(
    (get) => {
      const emptyAtomValue = get(emptyAtom);
      return emptyAtomValue !== EMPTY ? emptyAtomValue : null;
    },
    (_, set, newValue) => {
      set(emptyAtom, newValue);
    }
  );
  return emptyNullAtom;
};
