import styled, { css } from 'styled-components';
import { useRef } from 'react';
import NewBookingCalendar from './NewBookingCalendar';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const BookingCalendarWrapper = ({
  mobile = false,
  toggleCall,
  resetTripTime = false,
  onChange,
  hidePrice = false,
  hideMultiDay = false,
  dontFetch = false,
  className = null,
  autoApply = true,
  hideMobileHeader = false,
  monthsToShow = 2,
  mobileFullLength = false,
  onMultiDayChange = null,
  minDate = undefined,
  showCancel = true,
  maxDate = undefined,
  shownDate = undefined,
  showApply = true,
  showMonthAndYearPickers = false,
  date = new Date(),
  isBirthDate = false,
  isOwner = false,
}) => {
  const ref = useRef();

  useOnClickOutside(ref, toggleCall);

  if (mobile) {
    return (
      <>
        <BGOverlay />
        <MobileWrapper ref={ref} className={className} mobileFullLength={mobileFullLength}>
          <NewBookingCalendar
            showMonthAndYearPickers={showMonthAndYearPickers}
            resetTripTime={resetTripTime}
            hideMultiDay={hideMultiDay}
            hidePrice={hidePrice}
            showApply={showApply}
            shownDate={shownDate}
            date={date}
            mobile
            minDate={minDate}
            showCancel={showCancel}
            maxDate={maxDate}
            monthsToShow={monthsToShow}
            applyCallback={toggleCall}
            onChange={onChange}
            dontFetch={dontFetch}
            autoApply={autoApply}
            onMultiDayChange={onMultiDayChange}
            hideMobileHeader={hideMobileHeader}
            mobileFullLength={mobileFullLength}
            isBirthDate={isBirthDate}
            isOwner={isOwner}
          />
        </MobileWrapper>
      </>
    );
  }

  return (
    <DesktopWrapper ref={ref} className={className}>
      <NewBookingCalendar
        showMonthAndYearPickers={showMonthAndYearPickers}
        resetTripTime={resetTripTime}
        hideMultiDay={hideMultiDay}
        hidePrice={hidePrice}
        showApply={showApply}
        date={date}
        shownDate={shownDate}
        minDate={minDate}
        maxDate={maxDate}
        showCancel={showCancel}
        applyCallback={toggleCall}
        monthsToShow={monthsToShow}
        onMultiDayChange={onMultiDayChange}
        onChange={onChange}
        dontFetch={dontFetch}
        autoApply={autoApply}
        hideMobileHeader={hideMobileHeader}
        mobileFullLength={mobileFullLength}
        isBirthDate={isBirthDate}
        isOwner={isOwner}
      />
    </DesktopWrapper>
  );
};

const BGOverlay = styled.div`
  position: absolute;
  left: 0;
  background: rgba(10, 37, 47, 0.6);
  opacity: 1;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
`;

const MobileWrapper = styled.div<{ mobileFullLength: boolean }>`
  position: fixed;
  bottom: 0;
  background: white;
  width: 100vw;
  left: 0;
  height: 100%;
  max-height: calc(100% - 55px);
  padding: 20px;
  z-index: 100000;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${({ mobileFullLength }) =>
    mobileFullLength &&
    css`
      max-height: 100%;
    `}
`;

export const DesktopWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
  background: white;
  padding: 20px;
  transform: translateY(-70px) translateX(-50px);
  position: absolute;
  clip: auto;
  z-index: 10000000;
  width: min-content;
`;

export default BookingCalendarWrapper;
