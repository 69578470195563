/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DomesticV2TripsCalculatorGetRequest } from '../models';
// @ts-ignore
import { DomesticV2TripsGetRequest } from '../models';
// @ts-ignore
import { DomesticV2TripsIdInvitePatchRequest } from '../models';
// @ts-ignore
import { FavoriteCollection } from '../models';
// @ts-ignore
import { InvitationResponse } from '../models';
// @ts-ignore
import { InvitationResponseWithErrors } from '../models';
// @ts-ignore
import { InvitedTrip } from '../models';
// @ts-ignore
import { PlannedTrip } from '../models';
// @ts-ignore
import { TripCalculator } from '../models';
/**
 * ExploreApi - axios parameter creator
 * @export
 */
export const ExploreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calculate pricing of a new trip(new booking). For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} packageId The package ID
         * @param {'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration type
         * @param {boolean} [applyBoatingCredits] Apply boating credits for this booking
         * @param {string} [tripTime] The trip time
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [captainId] The ID of the captain.
         * @param {string} [captainPublicId] The UUID of the captain.
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCalculatorGet: async (packageId: string, duration: 'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours', applyBoatingCredits?: boolean, tripTime?: string, tripStart?: string, tripFinish?: string, passengers?: number, couponCode?: string, captainId?: string, captainPublicId?: string, insuranceRenterPolicyId?: string, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('domesticV2TripsCalculatorGet', 'packageId', packageId)
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('domesticV2TripsCalculatorGet', 'duration', duration)
            const localVarPath = `/domestic/v2/trips/calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (packageId !== undefined) {
                localVarQueryParameter['package_id'] = packageId;
            }

            if (applyBoatingCredits !== undefined) {
                localVarQueryParameter['apply_boating_credits'] = applyBoatingCredits;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (tripTime !== undefined) {
                localVarQueryParameter['trip_time'] = tripTime;
            }

            if (tripStart !== undefined) {
                localVarQueryParameter['trip_start'] = tripStart;
            }

            if (tripFinish !== undefined) {
                localVarQueryParameter['trip_finish'] = tripFinish;
            }

            if (passengers !== undefined) {
                localVarQueryParameter['passengers'] = passengers;
            }

            if (couponCode !== undefined) {
                localVarQueryParameter['coupon_code'] = couponCode;
            }

            if (captainId !== undefined) {
                localVarQueryParameter['captain_id'] = captainId;
            }

            if (captainPublicId !== undefined) {
                localVarQueryParameter['captain_public_id'] = captainPublicId;
            }

            if (insuranceRenterPolicyId !== undefined) {
                localVarQueryParameter['insurance_renter_policy_id'] = insuranceRenterPolicyId;
            }

            if (initializationSource !== undefined) {
                localVarQueryParameter['initialization_source'] = initializationSource;
            }

            if (displayedPage !== undefined) {
                localVarQueryParameter['displayed_page'] = displayedPage;
            }

            if (fullPricingShownToUser !== undefined) {
                localVarQueryParameter['full_pricing_shown_to_user'] = fullPricingShownToUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculate pricing of a new trip(new booking).
         * @param {DomesticV2TripsCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCalculatorPost: async (body: DomesticV2TripsCalculatorGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2TripsCalculatorPost', 'body', body)
            const localVarPath = `/domestic/v2/trips/calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCollectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/trips/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2TripsIdGet', 'id', id)
            const localVarPath = `/domestic/v2/trips/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {DomesticV2TripsIdInvitePatchRequest} [invite] A client can send a bulk of invitations to phones, emails, and existing boatsetters.  A successful response code is 200.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid phone will send a text notification to the invited phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsIdInvitePatch: async (id: string, invite?: DomesticV2TripsIdInvitePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2TripsIdInvitePatch', 'id', id)
            const localVarPath = `/domestic/v2/trips/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsInvitedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/trips/invited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsPlanningGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/trips/planning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2TripsGetRequest} [invite] A client can send a bulk of invitations to cell_phones, emails, and existing boatsetters.  A successful response code is 200.  The @favorite_id@ parameter is used to decide which collection of boats this invitation will range over.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid cell_phone will send a text notification to the invited cell_phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsPost: async (invite?: DomesticV2TripsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExploreApi - functional programming interface
 * @export
 */
export const ExploreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExploreApiAxiosParamCreator(configuration)
    return {
        /**
         * Calculate pricing of a new trip(new booking). For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} packageId The package ID
         * @param {'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration type
         * @param {boolean} [applyBoatingCredits] Apply boating credits for this booking
         * @param {string} [tripTime] The trip time
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [captainId] The ID of the captain.
         * @param {string} [captainPublicId] The UUID of the captain.
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsCalculatorGet(packageId: string, duration: 'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours', applyBoatingCredits?: boolean, tripTime?: string, tripStart?: string, tripFinish?: string, passengers?: number, couponCode?: string, captainId?: string, captainPublicId?: string, insuranceRenterPolicyId?: string, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TripCalculator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsCalculatorGet(packageId, duration, applyBoatingCredits, tripTime, tripStart, tripFinish, passengers, couponCode, captainId, captainPublicId, insuranceRenterPolicyId, initializationSource, displayedPage, fullPricingShownToUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calculate pricing of a new trip(new booking).
         * @param {DomesticV2TripsCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsCalculatorPost(body: DomesticV2TripsCalculatorGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TripCalculator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsCalculatorPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsCollectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FavoriteCollection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsCollectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlannedTrip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitedTrip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {DomesticV2TripsIdInvitePatchRequest} [invite] A client can send a bulk of invitations to phones, emails, and existing boatsetters.  A successful response code is 200.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid phone will send a text notification to the invited phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsIdInvitePatch(id: string, invite?: DomesticV2TripsIdInvitePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsIdInvitePatch(id, invite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsInvitedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvitedTrip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsInvitedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsPlanningGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlannedTrip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsPlanningGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2TripsGetRequest} [invite] A client can send a bulk of invitations to cell_phones, emails, and existing boatsetters.  A successful response code is 200.  The @favorite_id@ parameter is used to decide which collection of boats this invitation will range over.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid cell_phone will send a text notification to the invited cell_phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2TripsPost(invite?: DomesticV2TripsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlannedTrip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2TripsPost(invite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExploreApi - factory interface
 * @export
 */
export const ExploreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExploreApiFp(configuration)
    return {
        /**
         * Calculate pricing of a new trip(new booking). For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} packageId The package ID
         * @param {'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration type
         * @param {boolean} [applyBoatingCredits] Apply boating credits for this booking
         * @param {string} [tripTime] The trip time
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [captainId] The ID of the captain.
         * @param {string} [captainPublicId] The UUID of the captain.
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCalculatorGet(packageId: string, duration: 'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours', applyBoatingCredits?: boolean, tripTime?: string, tripStart?: string, tripFinish?: string, passengers?: number, couponCode?: string, captainId?: string, captainPublicId?: string, insuranceRenterPolicyId?: string, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: any): AxiosPromise<TripCalculator> {
            return localVarFp.domesticV2TripsCalculatorGet(packageId, duration, applyBoatingCredits, tripTime, tripStart, tripFinish, passengers, couponCode, captainId, captainPublicId, insuranceRenterPolicyId, initializationSource, displayedPage, fullPricingShownToUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculate pricing of a new trip(new booking).
         * @param {DomesticV2TripsCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCalculatorPost(body: DomesticV2TripsCalculatorGetRequest, options?: any): AxiosPromise<TripCalculator> {
            return localVarFp.domesticV2TripsCalculatorPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsCollectionsGet(options?: any): AxiosPromise<Array<FavoriteCollection>> {
            return localVarFp.domesticV2TripsCollectionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsGet(options?: any): AxiosPromise<Array<PlannedTrip>> {
            return localVarFp.domesticV2TripsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsIdGet(id: string, options?: any): AxiosPromise<InvitedTrip> {
            return localVarFp.domesticV2TripsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Identifier
         * @param {DomesticV2TripsIdInvitePatchRequest} [invite] A client can send a bulk of invitations to phones, emails, and existing boatsetters.  A successful response code is 200.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid phone will send a text notification to the invited phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsIdInvitePatch(id: string, invite?: DomesticV2TripsIdInvitePatchRequest, options?: any): AxiosPromise<InvitationResponse> {
            return localVarFp.domesticV2TripsIdInvitePatch(id, invite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsInvitedGet(options?: any): AxiosPromise<Array<InvitedTrip>> {
            return localVarFp.domesticV2TripsInvitedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsPlanningGet(options?: any): AxiosPromise<Array<PlannedTrip>> {
            return localVarFp.domesticV2TripsPlanningGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2TripsGetRequest} [invite] A client can send a bulk of invitations to cell_phones, emails, and existing boatsetters.  A successful response code is 200.  The @favorite_id@ parameter is used to decide which collection of boats this invitation will range over.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid cell_phone will send a text notification to the invited cell_phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2TripsPost(invite?: DomesticV2TripsGetRequest, options?: any): AxiosPromise<PlannedTrip> {
            return localVarFp.domesticV2TripsPost(invite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExploreApi - interface
 * @export
 * @interface ExploreApi
 */
export interface ExploreApiInterface {
    /**
     * Calculate pricing of a new trip(new booking). For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
     * @param {string} packageId The package ID
     * @param {'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration type
     * @param {boolean} [applyBoatingCredits] Apply boating credits for this booking
     * @param {string} [tripTime] The trip time
     * @param {string} [tripStart] ISO 8601 date
     * @param {string} [tripFinish] ISO 8601 date
     * @param {number} [passengers] 
     * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
     * @param {string} [captainId] The ID of the captain.
     * @param {string} [captainPublicId] The UUID of the captain.
     * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
     * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
     * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
     * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsCalculatorGet(packageId: string, duration: 'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours', applyBoatingCredits?: boolean, tripTime?: string, tripStart?: string, tripFinish?: string, passengers?: number, couponCode?: string, captainId?: string, captainPublicId?: string, insuranceRenterPolicyId?: string, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig): AxiosPromise<TripCalculator>;

    /**
     * Calculate pricing of a new trip(new booking).
     * @param {DomesticV2TripsCalculatorGetRequest} body Trip calculator JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsCalculatorPost(body: DomesticV2TripsCalculatorGetRequest, options?: AxiosRequestConfig): AxiosPromise<TripCalculator>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsCollectionsGet(options?: AxiosRequestConfig): AxiosPromise<Array<FavoriteCollection>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsGet(options?: AxiosRequestConfig): AxiosPromise<Array<PlannedTrip>>;

    /**
     * 
     * @param {string} id Identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<InvitedTrip>;

    /**
     * 
     * @param {string} id Identifier
     * @param {DomesticV2TripsIdInvitePatchRequest} [invite] A client can send a bulk of invitations to phones, emails, and existing boatsetters.  A successful response code is 200.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid phone will send a text notification to the invited phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsIdInvitePatch(id: string, invite?: DomesticV2TripsIdInvitePatchRequest, options?: AxiosRequestConfig): AxiosPromise<InvitationResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsInvitedGet(options?: AxiosRequestConfig): AxiosPromise<Array<InvitedTrip>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsPlanningGet(options?: AxiosRequestConfig): AxiosPromise<Array<PlannedTrip>>;

    /**
     * 
     * @param {DomesticV2TripsGetRequest} [invite] A client can send a bulk of invitations to cell_phones, emails, and existing boatsetters.  A successful response code is 200.  The @favorite_id@ parameter is used to decide which collection of boats this invitation will range over.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid cell_phone will send a text notification to the invited cell_phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApiInterface
     */
    domesticV2TripsPost(invite?: DomesticV2TripsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PlannedTrip>;

}

/**
 * ExploreApi - object-oriented interface
 * @export
 * @class ExploreApi
 * @extends {BaseAPI}
 */
export class ExploreApi extends BaseAPI implements ExploreApiInterface {
    /**
     * Calculate pricing of a new trip(new booking). For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
     * @param {string} packageId The package ID
     * @param {'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration type
     * @param {boolean} [applyBoatingCredits] Apply boating credits for this booking
     * @param {string} [tripTime] The trip time
     * @param {string} [tripStart] ISO 8601 date
     * @param {string} [tripFinish] ISO 8601 date
     * @param {number} [passengers] 
     * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
     * @param {string} [captainId] The ID of the captain.
     * @param {string} [captainPublicId] The UUID of the captain.
     * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
     * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
     * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
     * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsCalculatorGet(packageId: string, duration: 'full_day' | 'half_day' | 'two_hours' | 'three_hours' | 'six_hours', applyBoatingCredits?: boolean, tripTime?: string, tripStart?: string, tripFinish?: string, passengers?: number, couponCode?: string, captainId?: string, captainPublicId?: string, insuranceRenterPolicyId?: string, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsCalculatorGet(packageId, duration, applyBoatingCredits, tripTime, tripStart, tripFinish, passengers, couponCode, captainId, captainPublicId, insuranceRenterPolicyId, initializationSource, displayedPage, fullPricingShownToUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculate pricing of a new trip(new booking).
     * @param {DomesticV2TripsCalculatorGetRequest} body Trip calculator JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsCalculatorPost(body: DomesticV2TripsCalculatorGetRequest, options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsCalculatorPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsCollectionsGet(options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsCollectionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsGet(options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsIdGet(id: string, options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Identifier
     * @param {DomesticV2TripsIdInvitePatchRequest} [invite] A client can send a bulk of invitations to phones, emails, and existing boatsetters.  A successful response code is 200.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid phone will send a text notification to the invited phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsIdInvitePatch(id: string, invite?: DomesticV2TripsIdInvitePatchRequest, options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsIdInvitePatch(id, invite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsInvitedGet(options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsInvitedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsPlanningGet(options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsPlanningGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2TripsGetRequest} [invite] A client can send a bulk of invitations to cell_phones, emails, and existing boatsetters.  A successful response code is 200.  The @favorite_id@ parameter is used to decide which collection of boats this invitation will range over.  Each invitation will trigger a notification to be sent on the channels supported based on the invitation payload.  a valid email will send an email notification to the invited email.  If an invitation from the inviter exists for an email &#x3D;&gt;  a valid cell_phone will send a text notification to the invited cell_phone.  If an invitation from the inviter exists for a cell_phone &#x3D;&gt;  In the event of a failure, a response code of 422 will be returned with all the information necessary to render an alert. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public domesticV2TripsPost(invite?: DomesticV2TripsGetRequest, options?: AxiosRequestConfig) {
        return ExploreApiFp(this.configuration).domesticV2TripsPost(invite, options).then((request) => request(this.axios, this.basePath));
    }
}
