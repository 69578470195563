export const weekDays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};

export const ADVANCE_NOTICE_OPTIONS = {
  '1 hour': 1,
  '3 hours': 3,
  '6 hours': 6,
  '12 hours': 12,
  '1 day': 24,
  '3 days': 72,
  '7 days': 168,
};

export const MULTIBOOK_BUFFER_TIME_HOURS = {
  None: '0',
  '30 min': '0.5',
  '1 hour': '1.0',
  '2 hours': '2.0',
  '3 hours': '3.0',
};

export const DURATION_TO_TEXT = {
  two_hours: '2 hours',
  three_hours: '3 hours',
  half_day: '4 hours',
  six_hours: '6 hours',
  all_day: '8 hours',
};

export const DURATION_TO_NUMBER = {
  two_hours: 2,
  three_hours: 3,
  half_day: 4,
  six_hours: 6,
  all_day: 8,
  full_day: 8,
  multi_day: 8,
};

export const PROGRESS_P2P = {
  location: 0.125,
  title: 0.25,
  'cancellation-policy': 0.375,
  rules: 0.5,
  features: 0.625,
  photos: 0.75,

  insurance: 1.333,
  'insurance-info': 1.666,
  specifications: 1.875,

  availability: 2,
  'start-times': 2.2,
  'advance-notice': 2.4,
  'multi-booking': 2.8,

  'rental-type': 3.333,
  pricing: 3.666,

  review: 4,
  finished: 4,
  'add-more-boats': 4,
  help: 4,
};

export const PROGRESS_P2P_MOBILE = {
  details: 0,
  location: 0.083,
  title: 0.166,
  'cancellation-policy': 0.249,
  rules: 0.332,
  features: 0.415,
  photos: 0.498,
  specifications: 0.581,

  insurance: 0.664,
  'insurance-info': 0.747,

  availability: 0.8,
  'start-times': 0.83,
  'advance-notice': 0.86,
  'multi-booking': 0.89,

  'rental-type': 0.91,
  pricing: 0.95,

  review: 1,
  finished: 1,
  'add-more-boats': 1,
  help: 1,
};

export const PROGRESS_COMMERCIAL = {
  commercial: 0,
  international: 0,
  location: 0.15,
  title: 0.28,
  'cancellation-policy': 0.41,
  rules: 0.54,
  features: 0.67,
  photos: 0.8,
  specifications: 0.93,

  availability: 1,
  'start-times': 1.3,
  'advance-notice': 1.6,
  'multi-booking': 1.8,

  'rental-type': 2,
  pricing: 2.666,

  review: 3,
  finished: 3,
  'add-more-boats': 3,
  help: 3,
};

export const PROGRESS_COMMERCIAL_MOBILE = {
  commercial: 0,
  international: 0,
  location: 0.1,
  title: 0.2,
  'cancellation-policy': 0.3,
  rules: 0.4,
  features: 0.5,
  photos: 0.6,
  specifications: 0.7,

  availability: 0.81,
  'start-times': 0.84,
  'advance-notice': 0.86,
  'multi-booking': 0.88,

  'rental-type': 0.9,
  pricing: 0.95,

  review: 1,
  finished: 1,
  'add-more-boats': 1,
  help: 1,
};

export const TOP_MAKES = ['Bayliner', 'Beneteau USA', 'Catalina', 'Sea Ray Boats', 'Yamaha'];

export const LOCATION_TYPES = [
  'marina_slip',
  'marina_dry_storage',
  'marina_rack_storage',
  'marina_mooring',
  'residence_trailer',
  'residence_slip',
  'residence_mooring',
  'storage_facility',
  'storage_trailer',
];

export const BOAT_RULES_OPTIONS = [
  'shoes',
  'glass_bottles',
  'fishing',
  'pets',
  'kids_under_12',
  'smoking',
  'alcohol',
  'liveaboard',
  'swimming',
  'red_wine',
];

export const HULL_MATERIAL = [
  'fiberglass',
  'aluminum',
  'carbon_fiber',
  'composite',
  'inflatable',
  'ferro_cement',
  'plastic',
  'rigid_inflatable',
  'steel',
  'wood',
  'other',
];

export const POWER_CATEGORIES = [
  'airboat',
  'aluminum_fishing',
  'angler',
  'bow_rider',

  'bass_boat',
  'catamaran',
  'center_console',
  'classic',
  'commercial',
  'convertible',
  'cruiser',
  'cuddy_cabin',
  'deck_boat',
  'dive_boat',
  'downeast',
  'dual_console',
  'duck_boat',
  'electric',
  'express_cruiser',
  'fish_and_ski',
  'flats_boat',
  'flybridge',
  'houseboat',
  'inflatable_outboard',
  'jet_boat',
  'jet_ski_/_personal_water_craft',
  'jon_boat',
  'mega_yacht',
  'motor_yacht',
  'offshore_sport_fishing',
  'performance',
  'performance_fishing',
  'pilothouse',
  'pontoon',
  'rigid_inflatable',
  'runabout',
  'saltwater_fishing',
  'ski_and_wakeboard',
  'skiff',
  'trawler',
  'walkaround',
  'weekender',
  'other',
];

export const ENGINE_TYPES = [
  'direct_drive',
  'jet_drive',
  'inboard',
  'inboard_outboard',
  'outboard',
  'v_drive',
  'other',
];

export const SAIL_CATEGORIES = [
  'catamaran',
  'classic',
  'cruiser',
  'cruiser_racer',
  'cutter',
  'daysailer_&_weekender',
  'ketch',
  'motorsailer',
  'racer',
  'sloop',
  'schooner',
  'trimaran',
  'yawl',
  'other',
];

export const ALL_ROUTES = [
  'insurance-type',
  'details',
  'commercial',
  'location',
  'location-pin',
  'title',
  'description',
  'cancellation-policy',
  'rules',
  'features',
  'photos',
  'photos-upload',
  'insurance',
  'insurance-info',
  'rental-options',
  'pricing',
  'safety-standards',
  'safety-standards-agreement',
  'finished',
  'add-more-boats',
  'help',
];

export const INSURERS = {
  aaa: 'AAA',
  aig: 'AIG',
  allianz: 'Allianz',
  allstate: 'Allstate',
  american_family_insurance: 'American Family Insurance',
  american_modern: 'American Modern',
  amica: 'Amica',
  boat_us: 'BoatUS',
  chubb: 'Chubb',
  erie_insurance: 'Erie Insurance',
  farmers: 'Farmers',
  foremost: 'Foremost',
  geico: 'GEICO',
  hanover: 'Hanover',
  liberty_mutual: 'Liberty Mutual',
  markel: 'Markel',
  metlife: 'Metlife',
  nationwide: 'Nationwide',
  progressive: 'Progressive',
  skisafe: 'SkiSafe',
  statefarm: 'StateFarm',
  thehartford: 'The Hartford',
  travelers: 'Travelers',
  united_marine_underwriters: 'United Marine Underwriters',
  other: 'Other',
};

export const DETAILS_ROUTES = [
  'insurance-type',
  'details',
  'commercial',
  'location',
  'location-pin',
  'title',
  'description',
  'cancellation-policy',
  'rules',
  'features',
  'photos',
  'photos-upload',
  'specifications',
];

export const INSURANCE_ROUTES = ['insurance', 'insurance-info'];

export const PRICING_ROUTES = ['rental-options', 'duration'];

export const AVAILABILITY_ROUTES = ['availability', 'start-times', 'advance-notice', 'multi-booking'];

export const SUBMIT_ROUTES = ['review', 'finished', 'add-more-boats', 'help'];

export const STATES = [
  {
    abbr: 'AL',
    label: 'Alabama',
    value: 'Alabama',
  },
  {
    abbr: 'AK',
    label: 'Alaska',
    value: 'Alaska',
  },
  {
    abbr: 'AZ',
    label: 'Arizona',
    value: 'Arizona',
  },
  {
    abbr: 'AR',
    label: 'Arkansas',
    value: 'Arkansas',
  },
  {
    abbr: 'CA',
    label: 'California',
    value: 'California',
  },
  {
    abbr: 'CO',
    label: 'Colorado',
    value: 'Colorado',
  },
  {
    abbr: 'CT',
    label: 'Connecticut',
    value: 'Connecticut',
  },
  {
    abbr: 'DE',
    label: 'Delaware',
    value: 'Delaware',
  },
  {
    abbr: 'DC',
    label: 'District of Columbia',
    value: 'District of Columbia',
  },
  {
    abbr: 'FL',
    label: 'Florida',
    value: 'Florida',
  },
  {
    abbr: 'GA',
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    abbr: 'HI',
    label: 'Hawaii',
    value: 'Hawaii',
  },
  {
    abbr: 'ID',
    label: 'Idaho',
    value: 'Idaho',
  },
  {
    abbr: 'IL',
    label: 'Illinois',
    value: 'Illinois',
  },
  {
    abbr: 'IN',
    label: 'Indiana',
    value: 'Indiana',
  },
  {
    abbr: 'IA',
    label: 'Iowa',
    value: 'Iowa',
  },
  {
    abbr: 'KS',
    label: 'Kansas',
    value: 'Kansas',
  },
  {
    abbr: 'KY',
    label: 'Kentucky',
    value: 'Kentucky',
  },
  {
    abbr: 'LA',
    label: 'Louisiana',
    value: 'Louisiana',
  },
  {
    abbr: 'ME',
    label: 'Maine',
    value: 'Maine',
  },
  {
    abbr: 'MD',
    label: 'Maryland',
    value: 'Maryland',
  },
  {
    abbr: 'MA',
    label: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    abbr: 'MI',
    label: 'Michigan',
    value: 'Michigan',
  },
  {
    abbr: 'MN',
    label: 'Minnesota',
    value: 'Minnesota',
  },
  {
    abbr: 'MS',
    label: 'Mississippi',
    value: 'Mississippi',
  },
  {
    abbr: 'MO',
    label: 'Missouri',
    value: 'Missouri',
  },
  {
    abbr: 'MT',
    label: 'Montana',
    value: 'Montana',
  },
  {
    abbr: 'NE',
    label: 'Nebraska',
    value: 'Nebraska',
  },
  {
    abbr: 'NV',
    label: 'Nevada',
    value: 'Nevada',
  },
  {
    abbr: 'NH',
    label: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    abbr: 'NJ',
    label: 'New Jersey',
    value: 'New Jersey',
  },
  {
    abbr: 'NM',
    label: 'New Mexico',
    value: 'New Mexico',
  },
  {
    abbr: 'NY',
    label: 'New York',
    value: 'New York',
  },
  {
    abbr: 'NC',
    label: 'North Carolina',
    value: 'North Carolina',
  },
  {
    abbr: 'ND',
    label: 'North Dakota',
    value: 'North Dakota',
  },
  {
    abbr: 'OH',
    label: 'Ohio',
    value: 'Ohio',
  },
  {
    abbr: 'OK',
    label: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    abbr: 'OR',
    label: 'Oregon',
    value: 'Oregon',
  },
  {
    abbr: 'PA',
    label: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    abbr: 'RI',
    label: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    abbr: 'SC',
    label: 'South Carolina',
    value: 'South Carolina',
  },
  {
    abbr: 'SD',
    label: 'South Dakota',
    value: 'South Dakota',
  },
  {
    abbr: 'TN',
    label: 'Tennessee',
    value: 'Tennessee',
  },
  {
    abbr: 'TX',
    label: 'Texas',
    value: 'Texas',
  },
  {
    abbr: 'UT',
    label: 'Utah',
    value: 'Utah',
  },
  {
    abbr: 'VT',
    label: 'Vermont',
    value: 'Vermont',
  },
  {
    abbr: 'VA',
    label: 'Virginia',
    value: 'Virginia',
  },
  {
    abbr: 'WA',
    label: 'Washington',
    value: 'Washington',
  },
  {
    abbr: 'WV',
    label: 'West Virginia',
    value: 'West Virginia',
  },
  {
    abbr: 'WI',
    label: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    abbr: 'WY',
    label: 'Wyoming',
    value: 'Wyoming',
  },
];

export const COUNTRIES = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'United Kingdom',
    value: 'UK',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'Andorra',
    value: 'AD',
  },
  {
    label: 'Angola',
    value: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    label: 'Argentina Republic',
    value: 'AR',
  },
  {
    label: 'Armenia',
    value: 'AM',
  },
  {
    label: 'Aruba',
    value: 'AW',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Austria',
    value: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    label: 'Bahamas',
    value: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'Belarus',
    value: 'BY',
  },
  {
    label: 'Belgium',
    value: 'BE',
  },
  {
    label: 'Belize',
    value: 'BZ',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'BT',
  },
  {
    label: 'Botswana',
    value: 'BW',
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    label: 'Bolivia',
    value: 'BO',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    label: 'British Virgin Islands',
    value: 'VG',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    label: 'Burundi',
    value: 'BI',
  },
  {
    label: 'Cambodia',
    value: 'KH',
  },
  {
    label: 'Croatia',
    value: 'HR',
  },
  {
    label: 'Cameroon',
    value: 'CM',
  },
  {
    label: 'Cape Verde',
    value: 'CV',
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
  },
  {
    label: 'Chile',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Christmas Islands',
    value: 'CX',
  },
  {
    label: 'Cocos Islands',
    value: 'CC',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Comoros',
    value: 'KM',
  },
  {
    label: 'Congo',
    value: 'CG',
  },
  {
    label: 'Cook Islands',
    value: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    label: "Cote D'Ivoire",
    value: 'CI',
  },
  {
    label: 'Cyprus',
    value: 'CY',
  },
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
  },
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'DM',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
  },
  {
    label: 'East Timor',
    value: 'TP',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypt',
    value: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'SV',
  },
  {
    label: 'Estonia',
    value: 'EE',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    label: 'Falkland Islands',
    value: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'French Guiana',
    value: 'GF',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    label: 'French Southern Territories',
    value: 'TF',
  },
  {
    label: 'Gabon',
    value: 'GA',
  },
  {
    label: 'Gambia',
    value: 'GM',
  },
  {
    label: 'Georgia',
    value: 'GE',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Ghana',
    value: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    label: 'Greece',
    value: 'GR',
  },
  {
    label: 'Greenland',
    value: 'GL',
  },
  {
    label: 'Grenada',
    value: 'GD',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'GT',
  },
  {
    label: 'Guinea',
    value: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    label: 'Guyana',
    value: 'GY',
  },
  {
    label: 'Haiti',
    value: 'HT',
  },
  {
    label: 'Heard and McDonald Islands',
    value: 'HM',
  },
  {
    label: 'Honduras',
    value: 'HN',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    label: 'Hungary',
    value: 'HU',
  },
  {
    label: 'Iceland',
    value: 'IS',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Iraq',
    value: 'IQ',
  },
  {
    label: 'Indonesia',
    value: 'ID',
  },
  {
    label: 'Iran',
    value: 'IR',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Italy',
    value: 'IT',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Jordan',
    value: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'KI',
  },
  {
    label: 'Korea (North)',
    value: 'KP',
  },
  {
    label: 'Korea (South)',
    value: 'KR',
  },
  {
    label: 'Kuwait',
    value: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    label: 'Lao',
    value: 'LA',
  },
  {
    label: 'Latvia',
    value: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'LS',
  },
  {
    label: 'Liberia',
    value: 'LR',
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    label: 'Macau',
    value: 'MO',
  },
  {
    label: 'Macedonia',
    value: 'MK',
  },
  {
    label: 'Madagascar',
    value: 'MG',
  },
  {
    label: 'Malawi',
    value: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'MY',
  },
  {
    label: 'Maldives',
    value: 'MV',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Malta',
    value: 'MT',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Martinique',
    value: 'MQ',
  },
  {
    label: 'Mauritania',
    value: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'YT',
  },
  {
    label: 'Mongolia',
    value: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'MS',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    label: 'Micronesia',
    value: 'FM',
  },
  {
    label: 'Monaco',
    value: 'MC',
  },
  {
    label: 'Myanmar',
    value: 'MM',
  },
  {
    label: 'Namibia',
    value: 'NA',
  },
  {
    label: 'Nauru',
    value: 'NR',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Netherlands Antilles',
    value: 'AN',
  },
  {
    label: 'The Netherlands',
    value: 'NL',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    label: 'Niger',
    value: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'NG',
  },
  {
    label: 'Niue',
    value: 'NU',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Norway',
    value: 'No',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Panama',
    value: 'PA',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Palestine',
    value: 'PS',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'PY',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Philippines',
    value: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'PN',
  },
  {
    label: 'Poland',
    value: 'PL',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'Romania',
    value: 'RO',
  },
  {
    label: 'Russian Federation',
    value: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'RW',
  },
  {
    label: 'Samoa',
    value: 'WS',
  },
  {
    label: 'San Marino',
    value: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Serbia',
    value: 'ER',
  },
  {
    label: 'Seychelles',
    value: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Slovakia',
    value: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    label: 'Somalia',
    value: 'SO',
  },
  {
    label: 'South Africa',
    value: 'ZA',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'St. Helena',
    value: 'SH',
  },
  {
    label: 'St. Kitts and Nevis',
    value: 'KN',
  },
  {
    label: 'St. Lucia',
    value: 'LC',
  },
  {
    label: 'St. Pierre and Miquelon',
    value: 'PM',
  },
  {
    label: 'St. Vincent and the Grenadines',
    value: 'VC',
  },
  {
    label: 'Sudan',
    value: 'SD',
  },
  {
    label: 'Suriname',
    value: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    label: 'Swaziland',
    value: 'SZ',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'CH',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
  },
  {
    label: 'Taiwan',
    value: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Togo',
    value: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'TK',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    label: 'Uganda',
    value: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'UA',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    label: 'U.S. Minor Outlying Islands',
    value: 'UM',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    label: 'Venezuela',
    value: 'VE',
  },
  {
    label: 'Vietnam',
    value: 'VN',
  },
  {
    label: 'Virgin Islands (USA)',
    value: 'VI',
  },
  {
    label: 'Vatican City State',
    value: 'VA',
  },
  {
    label: 'Wallis and Futuna Islands',
    value: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'EH',
  },
  {
    label: 'Yemen',
    value: 'YE',
  },
  {
    label: 'Yugoslavia',
    value: 'YU',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
  },
];
