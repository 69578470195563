import cc from 'classcat';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import Cookies from 'js-cookie';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

export type ButtonVariant = 'renter' | 'owner' | 'captain' | 'yellow' | 'danger' | 'darkCaptain';

type Props = {
  component?: string;
  disabled?: boolean;
  onClick?: (e: unknown) => void;
  text: ReactNode;
  variant?: ButtonVariant;
  small?: boolean;
  outline?: boolean;
  loading?: boolean;
  toUppercase?: boolean;
  type?: string;
  justText?: boolean;
  className?: string;
  trackingId?: string;
  disableTracking?: boolean;
  href?: string;
  fullWidth?: boolean;
  id?: string;
};

export default function Button({
  component = 'button',
  type = 'button',
  disabled = false,
  onClick: onClickInherit,
  text = 'Submit',
  variant = 'renter',
  small = false,
  loading = false,
  outline = false,
  toUppercase = true,
  className,
  justText = false,
  trackingId = undefined,
  disableTracking = false,
  fullWidth = false,
  id,
  href,
  ...rest
}: Props) {
  const btnRef = useRef(null);
  const rudderTrack = useRudderStackTrack();

  const onClick = (e) => {
    const buttonRef = btnRef?.current;

    const gaClientId =
      window.ga && window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : Cookies.get('_ga')?.slice(6);

    const bodyRect = document.getElementById('app')?.getBoundingClientRect();
    const elemRect = buttonRef?.getBoundingClientRect();
    const offset = (elemRect?.top ?? 0) - (bodyRect?.top ?? 0);

    if (window.rudderanalytics && !disableTracking) {
      rudderTrack('CTA_clicked', {
        initial_anonymous_id: Cookies.get('initial_anonymous_id'),
        user_public_id: Cookies.get('user_public_id'),
        uuid: Cookies.get('uuid'),
        ga_client_id: gaClientId,
        button_props: {
          id: buttonRef.id || undefined,
          classname: buttonRef.className,
          tracking_id: trackingId,
          top_offset: offset,
          text: buttonRef?.innerText,
        },
      });
    }

    if (onClickInherit) onClickInherit(e);
  };

  return (
    <StyledButton
      data-testid={rest['data-testid']}
      as={component as any}
      type={type}
      href={href}
      id={id}
      ref={btnRef}
      className={[
        justText ? 'justText' : '',
        'button',
        `${variant}Bg`,
        disabled ? 'disabled' : '',
        small ? 'small' : '',
        toUppercase ? 'uppercase' : '',
        outline || justText ? 'outline' : '',
        href ? 'linkTag' : '',
        fullWidth ? 'fullWidth' : '',
        className,
      ]
        .join(' ')
        .trim()}
      disabled={disabled || loading}
      onClick={onClick}
    >
      <span
        className={cc([
          'buttonText',
          {
            loading,
          },
        ])}
      >
        {text}
      </span>
      {loading && <LoadingSpinner className="Button-loadingSpinner" />}
    </StyledButton>
  );
}

export const StyledButton = styled.button`
  border-radius: 6px;
  background-color: #0751c2;
  padding: 0px 34px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  white-space: normal;
  text-align: center;
  height: 55px;
  cursor: pointer;
  font-family: 'AvenirNext';
  font-size: 14px;
  font-weight: bold;
  text-transform: unset;
  margin: 0;

  &a {
    display: flex;
  }

  .Button-loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.linkTag {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.fullWidth {
    width: 100%;

    .buttonText {
      width: 100%;
    }
  }

  &.justText {
    border: none !important;
    font-size: 16px;
    font-weight: 500 !important;
    text-transform: initial !important;
  }

  &.small {
    padding: 10px 22px;

    .buttonText {
      font-size: 10px;
      font-weight: bold;
    }
  }

  &.raised {
    border: solid 1px #dbdfe5;
    background-color: #fff;
    padding: 17px;
  }

  .buttonText {
    text-transform: uppercase;
    font-family: 'AvenirNext', 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.79;
    text-transform: inherit;

    &.loading {
      opacity: 0;
    }
  }

  &.small .buttonText {
    font-size: 10px;
    font-weight: bold;
  }

  &.renterBg {
    background: #0751c2;
    color: white !important;
    &:hover {
      background: #0a4195;
    }
    &:active {
      background: #083170;
    }
    &.disabled {
      background: #dbdfe5 !important;
      border: 1px solid #dbdfe5 !important;
      color: white;
    }
    &.outline {
      border: 1px solid #0751c2;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #0751c2;
      }
      &:hover {
        &:not(.justText) {
          background: #0a4195;

          .buttonText {
            color: white;
          }
        }
      }
      &:active {
        border: 1px solid #083170;
        color: #083170;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }

  &.ownerBg {
    background: #0e7873;
    color: white !important;
    &:hover {
      background: #0c605c;
    }
    &:active {
      background: #154353;
    }
    &.disabled {
      background: #dbdfe5;
      border: 1px solid #dbdfe5;
      color: white;
    }
    &.outline {
      border: 1px solid #0e7873;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #0e7873;
      }
      &:hover {
        &:not(.justText) {
          background: #0c605c;
          .buttonText {
            color: white;
          }
        }
      }
      &:active {
        border: 1px solid #154353;
        color: #154353;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }

  &.captainBg {
    background: #00a2ad;
    color: white !important;
    &:hover {
      background: #00677d;
    }
    &:active {
      background: #0e7873;
    }
    &.disabled {
      background: #dbdfe5;
      border: 1px solid #dbdfe5;
      color: white;
    }
    &.outline {
      border: 1px solid #00a2ad;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #00a2ad;
      }
      &:hover {
        &:not(.justText) {
          .buttonText {
            color: #00677d;
          }
        }
      }
      &:active {
        border: 1px solid #00677d;
        color: #00677d;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }

  &.darkCaptainBg {
    background: #004e60;
    color: white !important;
    &:hover {
      background: #02949dff;
    }
    &:active {
      background: #02949dff;
    }
    &.disabled {
      background: #dbdfe5;
      border: 1px solid #dbdfe5;
      color: white;
    }
    &.outline {
      border: 1px solid #00677d;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #00677d;
      }
      &:hover {
        &:not(.justText) {
          border: 1px solid #00677d;
          color: #00677d;
          .buttonText {
            color: #00677d;
          }
        }
      }
      &:active {
        border: 1px solid #02949dff;
        color: #02949dff;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }

  &.yellowBg {
    background: #f4c064;
    color: #17233c !important;
    &:hover {
      background: #e7a433;
    }
    &:active {
      background: #d28d19;
    }
    &.disabled {
      background: #dbdfe5;
      border: 1px solid #dbdfe5;
      color: white;
    }
    &.outline {
      border: 1px solid #f4c064;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #f4c064;
      }
      &:hover {
        &:not(.justText) {
          background: #e7a433;

          .buttonText {
            color: #17233c;
          }
        }
      }
      &:active {
        border: 1px solid #d28d19;
        color: #d28d19;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }

  &.dangerBg {
    background: #bc350a;
    color: white !important;
    &:hover {
      background: #ac3009;
    }
    &:active {
      background: #ac3009;
    }
    &.disabled {
      background: #dbdfe5;
      border: 1px solid #dbdfe5;
      color: white;
    }
    &.outline {
      border: 1px solid #bc350a;
      background: white;
      color: #dbdfe5;
      .buttonText {
        color: #bc350a;
      }
      &:hover {
        &:not(.justText) {
          background: #ac3009;

          .buttonText {
            color: white;
          }
        }
      }
      &:active {
        border: 1px solid #ac3009;
        color: #ac3009;
      }
      &.disabled {
        background: white;
        border: 1px solid #dbdfe5;
        .buttonText {
          color: #dbdfe5;
        }
      }
    }
  }
`;
