import { mediaMLgMin, mediaMMdMax, mediaMSmMax } from 'helpers/breakpoints';
import styled from 'styled-components';
import { Box } from './box';
import { gap } from './mixins';

export const Flex = styled(Box)`
  display: flex;
  ${gap}
`;

export const FlexCol = styled(Flex)`
  flex-direction: column;
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const FlexRowReverse = styled(Flex)`
  flex-direction: row-reverse;
`;

export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`;

export const FlexAlignCenter = styled(Flex)`
  align-items: center;
`;

export const FlexAlignStart = styled(Flex)`
  align-items: flex-start;
`;

export const FlexAlignCenterJustifyEnd = styled(FlexAlignCenter)`
  justify-content: flex-end;
`;

export const FlexAlignCenterJustifyStart = styled(FlexAlignCenter)`
  justify-content: flex-start;
`;

export const FlexJustifyCenter = styled(Flex)`
  justify-content: center;
`;

export const FlexColAlignCenter = styled(FlexCol)`
  align-items: center;
`;

export const FlexColAlignCenterBoth = styled(FlexCol)`
  align-items: center;
  justify-content: center;
`;

export const FlexColAlignStart = styled(FlexCol)`
  align-items: flex-start;
`;

export const FlexColJustifyCenter = styled(FlexCol)`
  justify-content: center;
`;

export const FlexColCenterBoth = styled(FlexColAlignCenter)`
  justify-content: center;
`;

export const FlexCenterBoth = styled(FlexAlignCenter)`
  justify-content: center;
`;

export const FlexAlignCenterSpaceBetween = styled(FlexAlignCenter)`
  justify-content: space-between;
`;

export const FlexJustifySpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

export const FlexJustifySpaceBetweenAlignCenter = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const ItemFlex1 = styled(Box)`
  flex: 1;
`;

// Responsive variants
export const FlexColSmMax = styled(Flex)`
  ${mediaMSmMax} {
    flex-direction: column;
  }
`;

export const FlexColMdMax = styled(Flex)`
  ${mediaMMdMax} {
    flex-direction: column;
  }
`;

export const FlexRowMdMax = styled(Flex)`
  ${mediaMLgMin} {
    flex-direction: column;
  }
`;

export const FlexColAlignCenterLgMin = styled(FlexColAlignCenter)`
  ${mediaMMdMax} {
    align-items: flex-start;
  }
`;
