import { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { boatIdAtom } from 'components/checkout/v2/jotaiStore';
import { useBoatDetails, useGetBoatAvailableInsurancesQuery } from 'components/pdp/v3/hooks';
import { renterInsurerCompanyAtom } from 'components/checkout/buoy/jotaiStore';
import { getAsString, constructScreeningQuestionsQueryString } from 'helpers';
import { useRouter } from 'next/router';
import { useBookingTrip } from 'components/booking/queryHooks';
import dayjs from 'dayjs';
import {
  getBookingParamsForScreeningQuestions,
  getParamsForScreeningQuestions,
} from 'components/checkout/add-ons/helpers';
import { DURATION_TO_NUMBER } from 'components/forms/boat/constants';

export function useIsRenterInsuranceEnabled(bookingId?: string) {
  const [isBuoyLoading, setIsBuoyLoading] = useState(true);
  const [isApprovedForBuoy, setIsApprovedForBuoy] = useState(false);
  const router = useRouter();

  const [insurerCompany, setRenterInsuranceCompany] = useAtom(renterInsurerCompanyAtom);
  const { bookingTrip } = useBookingTrip({ enabled: !!bookingId }, bookingId);

  const packageId = !bookingId ? getAsString(router.query['booking[package_public_id]']) : bookingTrip?.package?.id;
  const boatId = useAtomValue(boatIdAtom);

  const {
    boatDetails: { packages: boatPackages },
    isLoading: isLoadingBoatDetails,
    isSuccess: isSuccessBoatDetails,
  } = useBoatDetails(boatId);

  const {
    boatAvailableInsurances,
    isSuccess,
    isLoading: isLoadingInsurances,
  } = useGetBoatAvailableInsurancesQuery(boatId);

  useEffect(() => {
    if (!isSuccess) return;
    if (!boatPackages) return;
    if (!boatAvailableInsurances) return;
    const boatPackage = boatPackages.find((p) => p.id === packageId);

    const canBeBuoy = boatPackage?.eligible_insurers?.includes('buoy');

    if (canBeBuoy) setRenterInsuranceCompany('buoy');

    setIsApprovedForBuoy(
      (boatAvailableInsurances.providers || []).some(
        (p) => p.name?.includes(insurerCompany || 'buoy') && p.status === 'approved' && canBeBuoy
      )
    );
    setIsBuoyLoading(isLoadingInsurances || isLoadingBoatDetails);
  }, [isSuccess, boatAvailableInsurances, insurerCompany, isLoadingInsurances, boatPackages]);

  return { isBuoyEnabled: isApprovedForBuoy, isBuoyLoading, isSuccess: isSuccess && isSuccessBoatDetails };
}

export const getValidInsurerFromString = (insurer: string, allowedInsurers: ('buoy' | 'boat_us')[] = []) => {
  let finalInsurer: 'buoy' | 'boat_us' = null;
  if (insurer === 'buoy') finalInsurer = 'buoy';
  if (insurer === 'boat_us') finalInsurer = 'boat_us';
  return allowedInsurers.includes(finalInsurer) ? finalInsurer : null;
};

export const redirectToScreeningIfNeeded = ({ queryParams, boatId, hasBookingId, bookingInfo, insuranceStatus }) => {
  if (insuranceStatus?.renter_insurance_status !== null) return;

  const trip_start = queryParams.tz
    ? dayjs.tz(`${queryParams?.trip_start}:${queryParams.trip_time}:00`, 'YYYY-MM-DD:HH:mm:ss', queryParams.tz).format()
    : null;
  const trip_finish = queryParams.tz
    ? dayjs
        .tz(`${queryParams?.trip_finish}:${queryParams.trip_time}:00`, 'YYYY-MM-DD:HH:mm:ss', queryParams.tz)
        .add(DURATION_TO_NUMBER[queryParams.duration], 'hours')
        .format()
    : null;

  const questionsParams = hasBookingId
    ? getBookingParamsForScreeningQuestions(bookingInfo)
    : getParamsForScreeningQuestions({
        ...queryParams,
        boat_public_id: boatId,
        trip_start: dayjs(trip_start).utc().format(),
        trip_finish: dayjs(trip_finish).utc().format(),
      });

  const questionsURLStringParams = constructScreeningQuestionsQueryString(questionsParams);

  window.location.href = `/insurers/buoy/renter_application/screening_questions?${questionsURLStringParams}&redirect_to=${encodeURIComponent(
    window.location.pathname + window.location.search
  )}`;
};
