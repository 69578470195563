import { InsurersApi } from 'swagger/apis/insurers-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { useQuery } from 'react-query';
import { useAtomValue } from 'jotai';
import { boatIdAtom, bookingIdAtom, isInquiryAtom } from 'components/checkout/v2/jotaiStore';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';
import { getCalculatorPricingFromURL } from 'components/checkout/add-ons/helpers';
import { useIsRenterInsuranceEnabled } from 'components/checkout/buoy/utils';
import { useEffect, useState } from 'react';
import { BoatLocation, DomesticV2InsurersInsurerIdRenterPoliciesPost201Response } from 'swagger/models';
import { renterInsurerCompanyAtom } from 'components/checkout/buoy/jotaiStore';
import dayjs from 'dayjs';
import { DURATION_TO_NUMBER } from 'components/forms/boat/constants';
import { IS_CLIENT } from 'utils';
import { valueEmpty } from 'helpers';
import { useUserDetails } from 'auth/mutations/userHooks';
import boatsetterPaths from 'utils/boatsetterPaths';
import { useBoatDetails } from 'components/pdp/v3/hooks';

const insurersApi = new InsurersApi(OpenApiConfiguration);

export const useGetMyBuoyInsuranceStatus = ({ isEnabled }: { isEnabled: boolean }) => {
  const insurerCompany = useAtomValue(renterInsurerCompanyAtom);

  const { data: { data } = {}, ...rest } = useQuery(
    ['domesticV2MeInsurersInsuranceStatusGet', insurerCompany || 'buoy'],
    () => insurersApi.domesticV2InsurersInsurerIdInsuranceStatusGet(insurerCompany || 'buoy'),
    { enabled: isEnabled }
  );

  return {
    data,
    ...rest,
  };
};

// local storage for insurance data (insurance id, etc)
export const useInsuranceDataStore = () => {
  if (!IS_CLIENT) return { insuranceData: {}, setInsuranceData: () => {} };
  const sessionKey = 'insuranceData';
  const getInsuranceData = window?.localStorage?.getItem(sessionKey) || '{}';
  const setInsuranceData = (data: DomesticV2InsurersInsurerIdRenterPoliciesPost201Response) =>
    window?.localStorage?.setItem(sessionKey, JSON.stringify(data));

  const removeInsuranceData = () => window?.localStorage?.removeItem(sessionKey);

  return {
    insuranceData: JSON.parse(getInsuranceData),
    setInsuranceData,
    removeInsuranceData,
  };
};

// local storage for trip details (trip start, trip finish, etc)
export const useTripDetailsStore = () => {
  if (!IS_CLIENT) return { tripDetails: {}, setTripDetails: () => {} };
  const sessionKey = 'tripDetails';
  const getTripDetails = window?.localStorage?.getItem(sessionKey) || '{}';
  const setTripDetails = (data: any) => window?.localStorage.setItem(sessionKey, JSON.stringify(data));

  return {
    tripDetails: JSON.parse(getTripDetails),
    setTripDetails,
  };
};

export const useInsurerRenterPoliciesQuery = () => {
  const bookingId = useAtomValue(bookingIdAtom);
  const boatId = useAtomValue(boatIdAtom);
  const isInquiry = useAtomValue(isInquiryAtom);
  const { insuranceData, setInsuranceData, removeInsuranceData } = useInsuranceDataStore();
  const { tripDetails, setTripDetails } = useTripDetailsStore();
  const { userId } = useUserDetails();
  const {
    boatDetails: { packages },
  } = useBoatDetails(boatId);
  const thisPackage = packages?.find((pac) => pac.id === tripDetails.package_id);

  const insurerCompany = useAtomValue(renterInsurerCompanyAtom);

  const { query, isReady } = useRouter();

  const parsedUrl = getCalculatorPricingFromURL(query);

  const { isBuoyEnabled, isSuccess: renterInsuranceSuccess } = useIsRenterInsuranceEnabled(bookingId);

  const { data: insuranceStatus, isSuccess: isSuccessStatus } = useGetMyBuoyInsuranceStatus({
    isEnabled: isBuoyEnabled && renterInsuranceSuccess,
  });

  const addHours = parsedUrl.duration === 'multi_day' ? 0 : DURATION_TO_NUMBER[parsedUrl.duration];
  const tripFinishTime = parsedUrl.duration === 'multi_day' ? '17:00' : parsedUrl.trip_time;

  const tripStart = parsedUrl.tz
    ? dayjs.tz(`${parsedUrl?.trip_start}:${parsedUrl.trip_time}:00`, 'YYYY-MM-DD:HH:mm:ss', parsedUrl.tz).format()
    : null;

  const tripFinish = parsedUrl.tz
    ? dayjs
        .tz(`${parsedUrl?.trip_finish}:${tripFinishTime}:00`, 'YYYY-MM-DD:HH:mm:ss', parsedUrl.tz)
        .add(addHours, 'hours')
        .format()
    : null;

  const requestToBookObject = {
    num_passengers: parsedUrl?.passengers,
    trip_start: dayjs(tripStart).utc().format(),
    trip_finish: dayjs(tripFinish).utc().format(),
    package_id: parsedUrl?.package_public_id,
    duration: parsedUrl?.duration,
  };

  const baseTripDetails = {
    userId,
  };

  const tripDetailsRequest = bookingId
    ? { ...baseTripDetails, booking_id: bookingId }
    : { ...baseTripDetails, ...requestToBookObject };

  // check if the trip details are different from the last
  // trip details to avoid unnecessary api calls to insurance_policies
  const tripDetailsNotEqual = !isEqual(tripDetailsRequest, tripDetails);

  const binding =
    insuranceStatus?.renter_insurance_status === 'approved' ||
    (thisPackage?.insurance_type === 'p2p' && isBuoyEnabled) ||
    false;

  const enabled =
    (isInquiry ? !!bookingId && !!boatId : !!boatId) && isReady && renterInsuranceSuccess && isSuccessStatus;

  if (enabled) {
    setTripDetails(tripDetailsRequest);
  }

  if (!isBuoyEnabled) {
    if (removeInsuranceData) removeInsuranceData();
  }

  const { data: { data } = {}, ...rest } = useQuery(
    [
      'domesticV2InsurersInsurerIdRenterPoliciesPost',
      isInquiry,
      boatId,
      bookingId,
      insurerCompany,
      insuranceStatus?.renter_insurance_status,
      requestToBookObject,
    ],
    () =>
      insurersApi.domesticV2InsurersInsurerIdRenterPoliciesPost(insurerCompany, {
        binding,
        booking_id: bookingId || undefined,
        ...(isInquiry ? {} : requestToBookObject),
      }),
    {
      // refetch only on component mount
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: enabled && (tripDetailsNotEqual || valueEmpty(insuranceData?.id)),
      retryDelay: 5000,
      retry: thisPackage?.insurance_type === 'p2p' ? 0 : 1,
    }
  );

  if (data?.id && data?.id !== undefined) {
    setInsuranceData(data);
  } else if (removeInsuranceData) removeInsuranceData();

  return {
    binding,
    isBuoyEnabled,
    data: data || insuranceData,
    policySuccess: isBuoyEnabled ? rest.isSuccess : true,
    ...rest,
  };
};

export const useBoatLastLocation = () => {
  const [value, setValue] = useState<null | string>(null);

  useEffect(() => {
    setValue(sessionStorage.getItem('lastLocation'));
  }, []);

  const writeToStorage = (location: BoatLocation) => {
    if (!location) return;
    sessionStorage.setItem('lastLocation', `${location.city}, ${location.state}, ${location.country_code}`);
  };

  return {
    value,
    writeToStorage,
  };
};

export const usePushToSimiliarBoats = (exclusive = false) => {
  const { value } = useBoatLastLocation();

  const router = useRouter();

  return () => {
    router.push({
      pathname: boatsetterPaths.search,
      query: {
        near: value || 'Fort Lauderdale, FL, USA',
        exclusive,
      },
    });
  };
};

export const useBuoyInsuranceQuestionsQuery = () => {
  // const { data: { data: buoyQuestions } = {} } = useQuery(
  //   ['insuranceQuestions'],
  //   () => insurersApi.domesticV2InsurersInsurerIdQuestionsGet('buoy', ['renter', 'owner']),
  //   {
  //     retry: 1,
  //   }
  // );

  // const insuranceQuestions = buoyQuestions?.renter?.questions;

  const questions = [].map((q) => {
    const { text, slug, type, default_answer, validations, answer } = q;

    return {
      text,
      slug,
      type,
      default_answer,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validations,
      answer,
    };
  });
  return { questions };
};
