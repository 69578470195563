import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { AxiosError } from 'axios';
import { TransformedAddonsToApiType } from 'components/checkout/add-ons/helpers';
import {
  tripStartDayjsAtom,
  tripFinishDayjsAtom,
  isBookingInitializedAtom,
  bookingCustomPriceAtom,
  bookingTripTimeAtom,
  bookingDurationAtom,
  selectedBookingPackageAtom,
  bookingCaptainIdAtom,
  bookingBoatIdAtom,
  tripStartFormattedAtom,
  tripFinishFormattedAtom,
  tripStartCalculatedAtom,
  tripFinishCalculatedAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useEffect } from 'react';
import { apiParamsHourNameMap } from 'components/forms/bookingWidget/utils';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import { passengersCountAtom } from 'components/search/v2/Filter/filterStore';
import { useAtomValue, useSetAtom } from 'jotai';
import { useQuery, UseQueryOptions } from 'react-query';
import { ExploreApi } from 'swagger/apis/explore-api';
import { BookingsApi } from 'swagger/apis/bookings-api';
import { StartTimeApi } from 'swagger/apis/start-time-api';
import { OwnerApi } from 'swagger/apis/owner-api';
import { CustomQueryOptions } from 'stacks/user/queryHooks';
import { Booking } from 'swagger/models';
import { useRouter } from 'next/router';
import { useInsuranceDataStore } from 'components/checkout/buoy/hooks';

const tripApi = new ExploreApi(OpenApiConfiguration);
const bookingsApi = new BookingsApi(OpenApiConfiguration);
const startTimeApi = new StartTimeApi(OpenApiConfiguration);
const ownerApi = new OwnerApi(OpenApiConfiguration);

export const useDefaultStartTimes = () => {
  const { data: { data: defaultStartTimes = [] } = {}, ...others } = useQuery(
    ['defaultStartTimes'],
    () => startTimeApi.domesticV2StartTimesGet(),
    {
      staleTime: Infinity,
    }
  );
  return {
    defaultStartTimes,
    ...others,
  };
};

export const getSpecialOfferPrice = (customPrice: number, bookingBoatPrice: number) => {
  if (!Number.isNaN(customPrice) && customPrice > 0 && customPrice !== bookingBoatPrice) {
    return customPrice;
  }
  return undefined;
};

export const getDisplayedPage = () => {
  let thisUrl;
  if (typeof window !== 'undefined') {
    thisUrl = window?.location?.href;
  }
  if (!thisUrl) return undefined;
  if (thisUrl.includes('add_ons')) {
    return 'add_ons';
  }
  if (thisUrl.includes('book_now')) {
    return 'checkout';
  }
  if (thisUrl.includes('inbox')) {
    return 'conversation';
  }
  if (thisUrl.includes('boats')) {
    return 'pdp';
  }
  return undefined;
};

export const useBookingPricing = (booking: Booking, { enabled = true }: { enabled?: boolean } = {}) => {
  const tripStart = useAtomValue(tripStartDayjsAtom);
  const tripFinish = useAtomValue(tripFinishDayjsAtom);
  const isBookingInitialized = useAtomValue(isBookingInitializedAtom);
  const passengersCount = useAtomValue(passengersCountAtom);
  const customPrice = useAtomValue(bookingCustomPriceAtom);
  const specialOfferPrice = getSpecialOfferPrice(customPrice, booking?.boat_price);
  const { query } = useRouter();
  const { insuranceData } = useInsuranceDataStore();

  return useBookingPricingBaseQuery(
    {
      bookingId: booking?.id,
      tripStart: tripStart?.toISOString(),
      tripFinish: tripFinish ? tripFinish?.toISOString() : tripStart?.toISOString(),
      passengers: passengersCount,
      specialOfferPrice,
      purchasingInsurance: query.renterInsurance === 'yes',
      insuranceRenterPolicyId: insuranceData?.id,
    },
    {
      keepPreviousData: true,
      enabled: enabled && isBookingInitialized && !!booking?.id,
    }
  );
};

// price calculator on PDP
export const useTripCalculator = (fetch = true) => {
  const tripTime = useAtomValue(bookingTripTimeAtom);
  const bookingDuration = useAtomValue(bookingDurationAtom);
  const isBookingInitialized = useAtomValue(isBookingInitializedAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const captainId = useAtomValue(bookingCaptainIdAtom);
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);
  const tripFinishFormatted = useAtomValue(tripFinishFormattedAtom);

  const { boatDetails: bookingBoatDetails, isLoading: isBoatDetailsLoading } = useBoatDetails(bookingBoatId);

  const durationName =
    apiParamsHourNameMap[bookingDuration?.value] === 'multi_day'
      ? 'full_day'
      : apiParamsHourNameMap[bookingDuration?.value];

  const enabled =
    isBookingInitialized && !!durationName && !!selectedBookingPackage?.id && !isBoatDetailsLoading && fetch;

  return useTripCalculatorBaseQuery(
    {
      tripStart: tripStartFormatted,
      tripFinish: tripFinishFormatted || tripStartFormatted,
      packageId: selectedBookingPackage?.id,
      duration: durationName,
      tripTime: tripTime?.value || '10:00',
      captainId:
        selectedBookingPackage?.package_type === 'captained' && bookingBoatDetails?.captain_network_enabled
          ? captainId
          : undefined,
      boatId: bookingBoatId,
      applyBoatingCredits: false,
    },
    { enabled, keepPreviousData: true }
  );
};

export type TripCalculatorPayload = {
  tripStart: string;
  tripFinish: string;
  packageId: string;
  duration: 'two_hours' | 'three_hours' | 'half_day' | 'six_hours' | 'full_day';
  tripTime: string;
  passengers?: number;
  boatId?: string;
  couponCode?: string;
  applyBoatingCredits?: boolean;
  captainId?: string;
  captainPublicId?: string;
  addons?: TransformedAddonsToApiType;
  insuranceRenterPolicyId?: string;
  maxPassengers?: number;
};

export const useTripCalculatorBaseQuery = (
  params: TripCalculatorPayload,
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof tripApi.domesticV2TripsCalculatorPost>>, AxiosError<any, any>>,
    'queryKey' | 'queryFn'
  >
) => {
  const {
    tripStart,
    tripFinish,
    packageId,
    passengers,
    duration,
    tripTime,
    captainId,
    couponCode,
    applyBoatingCredits,
    addons,
    captainPublicId,
    insuranceRenterPolicyId,
  } = params;

  const setTripStartCalculated = useSetAtom(tripStartCalculatedAtom);
  const setTripFinishCalculated = useSetAtom(tripFinishCalculatedAtom);

  const displayPage = getDisplayedPage();

  const { data: { data: tripCalculation = {} } = {}, ...other } = useQuery(
    [
      'tripCalculator',
      tripStart,
      tripFinish,
      packageId,
      passengers,
      duration,
      tripTime,
      captainId,
      captainPublicId,
      couponCode,
      applyBoatingCredits,
      addons,
      insuranceRenterPolicyId,
      displayPage,
    ],
    () =>
      tripApi.domesticV2TripsCalculatorPost({
        package_id: packageId,
        duration,
        apply_boating_credits: applyBoatingCredits,
        trip_time: tripTime,
        trip_start: tripStart,
        trip_finish: tripFinish,
        passengers,
        coupon_code: couponCode,
        captain_id: captainId,
        captain_public_id: captainPublicId,
        add_ons: addons,
        insurance_renter_policy_id: insuranceRenterPolicyId,
        displayed_page: displayPage,
        full_pricing_shown_to_user: displayPage === 'checkout',
      }),
    {
      ...options,
      keepPreviousData: true,
    }
  );

  // Move state updates to useEffect to prevent updates during render
  useEffect(() => {
    if (tripCalculation) {
      setTripStartCalculated(tripCalculation?.trip_start);
      setTripFinishCalculated(tripCalculation?.trip_finish);
    }
  }, [tripCalculation, setTripStartCalculated, setTripFinishCalculated]);

  return { tripCalculation, ...other };
};

export type BookingPricingQueryPayload = {
  bookingId: string;
  tripStart?: string;
  tripFinish?: string;
  passengers?: number;
  specialOfferPrice?: number;
  couponCode?: string;
  addons?: TransformedAddonsToApiType;
  purchasingInsurance?: boolean;
  insuranceRenterPolicyId?: string;
};

export const useBookingPricingBaseQuery = (
  params: BookingPricingQueryPayload,
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof bookingsApi.domesticV2BookingsIdCalculatorGet>>, AxiosError<any, any>>,
    'queryKey' | 'queryFn'
  >
) => {
  const {
    bookingId,
    tripStart,
    tripFinish,
    passengers,
    specialOfferPrice,
    couponCode,
    addons,
    purchasingInsurance,
    insuranceRenterPolicyId,
  } = params;

  const displayPage = getDisplayedPage();
  const { data: { data: bookingPricing = {} } = {}, ...other } = useQuery(
    [
      'bookingPricing',
      bookingId,
      tripStart,
      tripFinish,
      passengers,
      specialOfferPrice,
      couponCode,
      addons,
      purchasingInsurance,
      insuranceRenterPolicyId,
      displayPage,
    ],
    () =>
      bookingsApi.domesticV2BookingsIdCalculatorPost(bookingId, {
        trip_start: tripStart,
        trip_finish: tripFinish,
        passengers,
        special_offer_price: specialOfferPrice,
        coupon_code: couponCode,
        add_ons: addons,
        insurance_renter_policy_id: purchasingInsurance ? insuranceRenterPolicyId : null,
        displayed_page: displayPage,
        full_pricing_shown_to_user: displayPage === 'checkout',
      }),
    {
      ...options,
      keepPreviousData: true,
      enabled: (options?.enabled ?? true) && !!bookingId,
    }
  );

  return { bookingPricing, ...other };
};

export const useStripeBoatRequirementsQuery = (
  options?: CustomQueryOptions<typeof ownerApi.domesticV2OwnerStripeRequirementsGet>
) => {
  const { data: { data: stripeBoatRequirements = {} } = {}, ...other } = useQuery(
    ['stripeBoatRequirements'],
    () => ownerApi.domesticV2OwnerStripeRequirementsGet(),
    {
      ...options,
      keepPreviousData: true,
    }
  );

  return { stripeBoatRequirements, ...other };
};
