import styled, { css } from 'styled-components';
import { mediaMLgMin, mediaMMdMin, mediaMSmMax } from 'helpers/breakpoints';
import {
  GapPropsResponsive,
  border,
  BorderProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  sizing,
  SizingProps,
} from './mixins';

export interface BoxProps extends PaddingProps, MarginProps, SizingProps, BorderProps, GapPropsResponsive {
  flex?: string;
  order?: string;
  display?: string;
  cursor?: string;
  background?: string;
  position?: string;
  zIndex?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 100 | 1_000 | 10_000;
  overflowWrap?: 'normal' | 'break-word';
}

export interface BoxPropsResponsive extends BoxProps {
  mdMin?: BoxProps;
  lgMin?: BoxProps;
  smMax?: BoxProps;
}

export const Box = styled.div<BoxPropsResponsive>`
  ${padding}
  ${margin}
  ${sizing}
  ${border}

  ${(props) => buildBoxCSS(props)};

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildBoxCSS(mdMin)}
  }
  ${mediaMSmMax} {
    ${({ smMax = {} }) => buildBoxCSS(smMax)}
  }
  ${mediaMLgMin} {
    ${({ lgMin = {} }) => buildBoxCSS(lgMin)}
  }
`;

const buildBoxCSS = ({ flex, order, display, cursor, background, zIndex, overflowWrap, position }: BoxProps) => css`
  flex: ${flex};
  order: ${order};
  display: ${display};
  cursor: ${cursor};
  background: ${background};
  position: ${position};
  z-index: ${zIndex};
  overflow-wrap: ${overflowWrap};
`;
